import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Dnp830topic1assignment = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>DNP 830 Topic 1 | Key Research Methods & Designs Breakdown</title>
                <meta name='description'
                    content='Unlock effective DNP 830 Topic 1 Assignment Examination Methods and Designs strategies. Click now for expert tips and guidance!
' />
                <meta name='keywords' content='DNP 830 Topic 1 Assignment Examination Methods and Designs' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>DNP 830 Topic 1 Assignment < br /><span>Examination Methods and Designs
                    </span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Dnp830topic1assignment.webp" alt="DNP 830 Topic 1 Assignment Examination Methods and Designs" />
                    <div className='main-container-sl-content'>
                        <h2>Examination Methods and Designs</h2>
                        <div>
                            <div dir="auto" data-message-author-role="assistant" data-message-id="bb2063c9-380c-4f4b-95cb-c5022cf294ba" data-message-model-slug="gpt-4o-mini">
                                <div>
                                    <ul>
                                        <li><strong>Importance of Research Methods and Designs in Healthcare Studies</strong></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p>Research methods and designs are the essential structural parts of any study since they characterize how data will be gathered, analyzed, and unraveled. Consequently, information on such methods and designs is vital for assessing the validity and believability of the results in the healthcare domain and comparing results obtained from various investigations (Battisto et al., 2022).</p>
                        <ul>
                            <li>
                                <h3>Comparing Research Methods and Designs in Healthcare Studies</h3>
                            </li>
                        </ul>
                        <p>This paper compares the methods and designs of three unmistakable research articles: one focusing on a partnership intervention in-home visits to enhance patient satisfaction, another on the concept and attitude of medication care among dialysis patients, and the third to assess the IPC practices among nursing students.</p>
                        <p>The emphasis is placed on the nature of the methods and designs used in each of the examinations, the statistical methods used, and issues of reliability and validity in these ways of reasoning.</p>
                        <h2>Methods of Each Article</h2>
                        <h3>Article 1: A mental health home visit service partnership intervention on improving patients' satisfaction.</h3>
                        <p>The primary article applied the time series quasi-experimental quantitative research plan. This approach enabled the researchers to assess the dynamic responses of patient satisfaction with time in a study on the partnership between local area-based and hospital-based home visits.</p>
                        <p>The strategy included comparing two gatherings: An intervention pack that offered the treatment and a no-treatment bunch that continued with the regular home visits. Patient satisfaction was assessed on various occasions, namely pre-intervention, six months after, and 12 months after, to guarantee a longitudinal comparison (Cheng et al., 2018).</p>
                        <h3>Article 2: Chronic drug treatment among hemodialysis patients: A qualitative study of patients, nursing and medical staff attitudes and approaches.</h3>
                        <p>The second article used a qualitative research strategy grounded on the grounded speculation research reasoning. This study's research question was to establish patients' and staff's understanding and perception of medication care in a dialysis unit.</p>
                        <p>The data was collected through interviews with the patients and the staff, where gatherings were semi-organized. The participants were evaluated, and the data gathered was analyzed using grounded speculation to establish patterns and subjects regarding medication care with the point of view of comparing them with the quantitative results and self-perception (Gilad et al., 2020).</p>
                        <h3>Article 3: Factors Influencing Hand Hygiene Practice of Nursing Students: A Descriptive, mixed-methods Study</h3>
                        <p>The third article recollected quantitative and qualitative approaches in two sections. Phase one assessed nursing students' IPC information quantitatively using a standardized questionnaire. The questionnaire also revealed practice impacts. Phase Two used qualitative semi-organized interviews with clinical facilitators to examine students' IPC practices during clinical placement. This mixed-methods study examined nursing students' IPC information and practice (Zimmerman et al., 2020).</p>
                        <h2>Design of Each Article</h2>
                        <h3>Article 1: Time Series Quasi-Experimental Design</h3>
                        <p>The fundamental article utilized a time series quasi-experimental procedure with emphasized evaluations. The need for conflicting tasks in experimental and control packs is seen in this procedure from a genuine experimental assessment. The time series part allows you to see intercession-related subjects and changes (Cheng et al., 2018).</p>
                        <h3>Article 2: Grounded Theory Qualitative Design</h3>
                        <p>The subsequent article utilized exploratory grounded theory to cultivate theory from information. The design checks out subjects and contemplations by zeroing in on affluent, qualitative information from interviews. Iterative information get-together and evaluation in grounded theory make a speculative design thinking about people's lived encounters (Gilad et al., 2020).</p>
                        <h3>Article 3: Two-Phase Descriptive Mixed-Methods Design</h3>
                        <p>The third paper utilized a two-phase descriptive mixed-methods design. The hidden part was a quantitative cross-sectional review of nursing understudies' information. The qualitative second phase investigated clinical facilitators' pieces of information utilizing a descriptive design (Zimmerman et al., 2020). In the meantime, coordinating quantitative and qualitative data gave a total handle on the circumstances.</p>
                        <h2>Statistical Analysis Associated with the Method and Design</h2>
                        <h3>Article One: Statistics</h3>
                        <p>In the fundamental paper, illuminating and inferential statistics focused on understanding satisfaction after some time. Isolating satisfaction throughout three-time concentrates used matched t-tests or underlined measures ANOVA. The assessment focused on enormous changes between pre-intercession, half-year, and year satisfaction scores, especially among exploratory and control gatherings (Cheng et al., 2018).</p>
                        <h3>Article Two: Statistics</h3>
                        <p>Reasonable evaluation blocks statistical analysis for the accompanying article. The study used grounded theory methods, including thematic coding and trustworthy comparison. Coding and referencing interview data uncovered themes and models (Gilad et al., 2020). Synthesizing these thoughts conveyed a theoretical explanation of solution thought differences and mindsets.</p>
                        <h3>Article 3: Statistics</h3>
                        <p>Stage One of the third article used expressive statistics to summarize nursing students' IPC data and inferential statistics to see practice factors. Chi-square tests or choosing to lose confidence could have wrapped up data influencing component affiliations. Stage Two used close and personal thematic analysis of clinical facilitator gatherings to reveal student IPC themes (Zimmerman et al., 2020).</p>
                        <h2>Reliability and Validity Issues Associated with Each Methodology and Design</h2>
                        <h3>Article One: Reliability and Validity</h3>
                        <p>The difficulty of randomization in the central article's semi-exploratory design raises issues concerning inward validity and confirmation propensity. Nonetheless, the time series brand name deals with the design by offering several server homesteads to track down designs across an entrance. Non-generalizable disclosures could lessen outside validity (Cheng et al., 2018). Standardized patient satisfaction assessments pay particular attention to reliability, even though their consistent quality may be tested over an excessively long time.</p>
                        <h3>Article Two: Reliability and Validity</h3>
                        <p>The resulting article uses reasonable, grounded theory to regard trustworthiness and flexibility over reliability and validity. Semi-worked with interviews to ensure part consistency, supporting the openings. Thematic analysis is dynamic. Therefore, examiners could unravel data incredibly, impacting reliability. Triangulation &mdash; isolating revelations and past quantitative assessment &mdash; increases validity, dismissing how conclusions may practically identical circumstances (Gilad et al., 2020).</p>
                        <h3>Article 3: Reliability and Validity</h3>
                        <p>The third article's mixed methodologies design further makes reliability and validity by solidifying quantitative and reasonable methods. Upheld surveys work on inside reliability and content validity in Stage One. The progressive design, where quantitative openings enlighten near and dear appraisal, further makes thought validity by uncovering IPC practice centers. If the model isn't illustrative of nursing students, outside validity could progress (Zimmerman et al., 2020). Thematic analysis ensures Stage Two's reliability, while the exciting assessment is one-sided.</p>
                        <h2>Methods and Designs Summary and Comparison</h2>
                        <ul>
                            <li>
                                <h3>Comparing Approaches in Evaluation Methodology and Design</h3>
                            </li>
                        </ul>
                        <p>The three scatterings evaluated in this appraisal used vacillated approaches and designs to address different assessment issues and conditions. The vital article's semi-groundwork design and quantitative method made mediation evaluation on liberal satisfaction a long stretch.</p>
                        <p>This methodology saw massive upgrades without the fastidiousness of a randomized controlled starter (Cheng et al., 2018). The ensuing article's grounded theory design and significant method were undeniably appropriate for focusing on patients' and staff's tangled, applied game plan thought experiences and mindsets (Gilad et al., 2020). It gave significance and information, not statistical generalizability like quantitative assessment.</p>
                        <ul>
                            <li>
                                <h3>Evaluating Methodological Approaches in Nursing Research</h3>
                            </li>
                        </ul>
                        <p>The third article's mixed methods design consolidated quantitative and applied data for comprehensive and fundamental analysis. The successive methodology allowed experts to assess nursing students' data and analyze significant components affecting IPC practices. This mix of methods revolves around validity; however, stunned data sets out some reasonable compromise and understanding (Zimmerman et al., 2020).</p>
                        <h3>DNP 830 Topic 1 Assignment Examination Methods and Designs</h3>
                        <p>Taking a gander at the three evaluations shows that each methodology and design certainly fit the survey targets. The focal article included quantitative methods for wary assessment and comparison, the second many-sided unique methods for rich, immense encounters, and the third used mixed methods to change significant and quantitative evaluation (Cheng et al., 2018). Methodological carefulness is fundamental to getting solid and adaptable divulgences, as each study's reliability and validity concerns depend on its methodology and designs (Battisto et al., 2022).</p>
                        <h2>Conclusion</h2>
                        <p>&mdash; quantitative, up close and personal, or mixed &mdash; have advantages and shortcomings that impact reliability and validity. In&nbsp;the <strong>context of DNP 830 Topic 1 Assignment Examination Methods and Designs</strong>, understanding these properties helps experts select the best methodology for their assessment objectives, leading to more robust and meaningful clinical benefits studies.</p>
                        <h2>References</h2>
                        <p>Battisto, D., Li, X., Dong, J., Hall, L., &amp; Blouin, J. (2022). Research methods used in evidence-based design: An analysis of five years of research articles from the <em>herd journal</em>. <em>HERD: Health Environments Research &amp; Design Journal</em>, <em>16</em>(1), 56&ndash;82.</p>
                        <p><a href="https://doi.org/10.1177/19375867221125940">https://doi.org/10.1177/19375867221125940</a></p>
                        <p>Cheng, J.-F., Huang, X.-Y., Lin, M.-C., Wang, Y.-H., &amp; Yeh, T.-P. (2018). A mental health home visit service partnership intervention on improving patients&rsquo; satisfaction. <em>Archives of Psychiatric Nursing</em>, <em>32</em>(4), 610&ndash;616.</p>
                        <p><a href="https://doi.org/10.1016/j.apnu.2018.03.010">https://doi.org/10.1016/j.apnu.2018.03.010</a></p>
                        <p>Gilad, L., Haviv, Y. S., Cohen-Glickman, I., Chinitz, D., &amp; Cohen, M. J. (2020). Chronic drug treatment among hemodialysis patients: A qualitative study of patients, nursing and medical staff attitudes and approaches. <em>BMC Nephrology</em>, <em>21</em>(1).</p>
                        <p><a href="https://doi.org/10.1186/s12882-020-01900-y">https://doi.org/10.1186/s12882-020-01900-y</a></p>
                        <p>Zimmerman, P.-A. P., Sladdin, I., Shaban, R. Z., Gilbert, J., &amp; Brown, L. (2020). Factors influencing hand hygiene practice of nursing students: A descriptive, mixed-methods study. <em>Nurse Education in Practice</em>, <em>44</em>, 102746.</p>
                        <p><a href="https://doi.org/10.1016/j.nepr.2020.102746">https://doi.org/10.1016/j.nepr.2020.102746</a></p>
                        <h2>Appendix</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>Article</p>
                                    </td>
                                    <td>
                                        <p>Sample</p>
                                    </td>
                                    <td>
                                        <p>Method</p>
                                    </td>
                                    <td>
                                        <p>Design</p>
                                    </td>
                                    <td>
                                        <p>Reliability/Trustworthy</p>
                                    </td>
                                    <td>
                                        <p>Analysis</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Cheng, J.-F., Huang, X.-Y., Lin, M.-C., Wang, Y.-H., &amp; Yeh, T.-P. (2018). A mental health home visit service partnership intervention on improving patients&rsquo; satisfaction. <em>Archives of Psychiatric Nursing</em>, <em>32</em>(4), 610&ndash;616. <a href="https://doi.org/10.1016/j.apnu.2018.03.010">https://doi.org/10.1016/j.apnu.2018.03.010</a></p>
                                    </td>
                                    <td>
                                        <p>The sample consisted of patients receiving home visit services.</p>
                                        <p>Experimental Group: Received the "partnership intervention".</p>
                                        <p>Control Group: Received routine home visits.</p>
                                    </td>
                                    <td>
                                        <p>Intervention: Partnership intervention between community-based and hospital-based home visit services.</p>
                                        <p>Measurements: Patient satisfaction was measured at three points: pre-intervention, six months post-intervention, and 12 months post-intervention.</p>
                                        <p>Data Collection: Quantitative data was collected to assess changes in patient satisfaction over time.</p>
                                    </td>
                                    <td>
                                        <p>Design Type: Time series quasi-experimental design.</p>
                                        <p>Groups: Experimental group (partnership intervention) and control group (routine home visits).</p>
                                        <p>Temporal Dimension: Measures taken at multiple time points to assess the longitudinal effects of the intervention.</p>
                                    </td>
                                    <td>
                                        <p>Reliability: The study utilized a quasi-experimental design, which can provide reliable data if control over extraneous variables is maintained.</p>
                                        <p>Trustworthiness: The study's trustworthiness depends on methodological rigor, including the control over confounding factors and robustness of data analysis.</p>
                                    </td>
                                    <td>
                                        <p>Statistical Analysis: Likely involved statistical tests (e.g., ANOVA, t-tests) to compare satisfaction scores between groups and across time points.</p>
                                        <p>Findings: Identified significant improvements in patient satisfaction metrics related to disease stabilization, daily living abilities, communication, and resource provision post-intervention.</p>
                                        <p>Long-term Effectiveness: Noted a potential decline in intervention effects at 12 months, suggesting a need for ongoing support or reevaluation of the intervention's sustainability.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Gilad, L., Haviv, Y. S., Cohen-Glickman, I., Chinitz, D., &amp; Cohen, M. J. (2020). Chronic drug treatment among hemodialysis patients: A qualitative study of patients, nursing and medical staff attitudes and approaches. <em>BMC Nephrology</em>, <em>21</em>(1). <a href="https://doi.org/10.1186/s12882-020-01900-y">https://doi.org/10.1186/s12882-020-01900-y</a></p>
                                    </td>
                                    <td>
                                        <p>The sample consisted of patients and staff (nurses and physicians) from the dialysis unit at Hadassah Medical Center, Jerusalem, Israel.</p>
                                        <p>These participants were selected based on their involvement in the medication care process within the dialysis unit.</p>
                                    </td>
                                    <td>
                                        <p>Approach: Qualitative research using the grounded theory approach.</p>
                                        <p>Data Collection: Semi-structured, in-depth interviews were conducted with both patients and staff to explore their concepts and attitudes regarding medication care.</p>
                                        <p>Setting: The study took place in the same dialysis unit previously studied in the earlier quantitative research.</p>
                                    </td>
                                    <td>
                                        <p>Design Type: Qualitative research design using grounded theory.</p>
                                        <p>Interviews: The design focused on capturing the in-depth perspectives and attitudes of patients and staff about medication care through open-ended questions and repeated probing.</p>
                                    </td>
                                    <td>
                                        <p>Credibility: The study's reliability is enhanced by the use of in-depth interviews, which allow for detailed exploration of the participants' perspectives. The grounded theory approach adds rigor by enabling the identification of patterns and themes from the data.</p>
                                        <p>Trustworthiness: Trustworthiness is achieved through repeated questioning, which uncovered discrepancies between the staff's and patients' perceptions, and by triangulating findings with previous quantitative data.</p>
                                    </td>
                                    <td>
                                        <p>Thematic Analysis: Data from interviews were analyzed using grounded theory, which involves coding and categorizing data to identify key themes and patterns.</p>
                                        <p>Findings: The analysis revealed a disconnect between patient and staff perceptions regarding medication care, highlighting distrust from staff and feelings of being belittled among patients. Laboratory tests were identified as a significant but previously underappreciated factor influencing medication care.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Zimmerman, P.-A. P., Sladdin, I., Shaban, R. Z., Gilbert, J., &amp; Brown, L. (2020). Factors influencing hand hygiene practice of nursing students: A descriptive, mixed-methods study. <em>Nurse Education in Practice</em>, <em>44</em>, 102746. <a href="https://doi.org/10.1016/j.nepr.2020.102746">https://doi.org/10.1016/j.nepr.2020.102746</a></p>
                                    </td>
                                    <td>
                                        <p>Phase One: Nursing students enrolled in their second and third year of a Bachelor of Nursing program at an Australian university.</p>
                                        <p>Phase Two: Clinical facilitators involved in supervising nursing students during their clinical placements.</p>
                                    </td>
                                    <td>
                                        <p>Phase One: Utilized an anonymous validated questionnaire to assess nursing students' knowledge of infection prevention and control (IPC), with a focus on hand hygiene. The questionnaire also identified variables influencing students' IPC practices.</p>
                                        <p>Phase Two: Conducted semi-structured interviews with clinical facilitators to explore their experiences and perceptions of students&rsquo; IPC practices during clinical placement.</p>
                                    </td>
                                    <td>
                                        <p>Design Type: Two-phase descriptive, mixed-method study.</p>
                                        <p>Phase One: Quantitative approach using a validated questionnaire to gather data on students&rsquo; theoretical knowledge of IPC and related variables.</p>
                                        <p>Phase Two: Qualitative approach using semi-structured interviews to gain insights into clinical facilitators' perceptions and experiences.</p>
                                    </td>
                                    <td>
                                        <p>Reliability: The study used a validated questionnaire in Phase One, ensuring the reliability of the data collected on students' knowledge of IPC. Descriptive and inferential analyses were conducted to provide robust findings.</p>
                                        <p>Trustworthiness: In Phase Two, the trustworthiness of the qualitative data was enhanced through thematic analysis, which identified recurring themes. The combination of quantitative and qualitative methods increases the overall reliability and validity of the study&rsquo;s conclusions.</p>
                                    </td>
                                    <td>
                                        <p>Phase One Analysis: Descriptive and inferential statistical analyses were performed on the questionnaire data to assess the knowledge levels and identify variables influencing IPC practices among nursing students.</p>
                                        <p>Phase Two Analysis: Thematic analysis of the semi-structured interviews with clinical facilitators led to the identification of five key themes: understanding workplace culture, students&rsquo; modeling of local behavior, enhancing and consolidating knowledge for practice, adjusting to practice reality, and accessing additional hand hygiene resources.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

        </>
    )

}

export default Dnp830topic1assignment
