import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx9904assessment3 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 9904 Assessment 3 Doctoral Project Report</title>
                <meta name='description'
                    content='Learn from NURS FPX 9904 Assessment 3 Doctoral Project Report about bridging healthcare gaps through telehealth. Click to read more!' />
                <meta name='keywords' content='NURS FPX 9904 Assessment 3 Doctoral Project Report' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 9904 Assessment 3 < br /><span>Doctoral Project Report</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nursfpx9904assessment3.webp" alt="NURS FPX 9904 Assessment 3 Doctoral Project Report" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>NURS FPX 9904 Assessment 3 Doctoral Project Report.</strong></em></li>
                        </ul>
                        <h2>Project Summary Report</h2>
                        <p>Pleasant discussions through telehealth, for instance, those through the improvement of innovation have gained some amazing advancement in enhancing the improvement of healthcare especially to the nation areas. To begin, the audit targets addressing the subject Telehealth and its relationship with patient satisfaction in country areas. The hypothesis of the ongoing survey is that the Telehealth medicines can refresh the patient satisfaction especially among patients in the provincial areas.</p>
                        <ul>
                            <li>
                                <h3>Telehealth Gaps and Insights</h3>
                            </li>
                        </ul>
                        <p>To be more precise, far-away areas often don't give various decisions of a substitute kind of health care to examine. Telehealth may be basic in this cycle however more data is lacking on the reasonability of this intervention (Smith and associates, 2020). The reasoning for this study is that there is a pending requirement for additional information on the kind of examinations published concerning employing telemedicine in health care offices coordinated in distant districts.</p>
                        <p>Another district that hasn't been based on much in this study that is raised in the problem statement: and patient satisfaction levels in these areas as seen by Johnson and Green (2019) for the video. This study could have a few implication in approach and that makes it an area of investigation for Telehealth administrations basic.</p>
                        <h2>Background and Organization Gap Analysis</h2>
                        <p>Thusly, telehealth is seen as maybe of the most original district within a healthcare framework today, particularly when used to address the necessities of a general public struggling to get to a healthcare office eye to eye yet requires clinical thought. Telehealth thusly assists in this by allowing occasions, clinical tests and continuous monitoring to be driven without genuine contact.</p>
                        <p>It fosters the straightforwardness and adaptability of the health care administration (Smith et al. , 2020). Ensuring permission to health in far away districts is a test as a monstrous number of patients exist, a couple of specialists are open, and the straightforwardness of gadgets is confined. These things suggest the young people in such a spot are at a more serious risk of being or becoming less healthy or having more unfortunate health than youngsters in metropolitan organizations.</p>
                        <ul>
                            <li>
                                <h3>Telehealth Challenges and Benefits</h3>
                            </li>
                        </ul>
                        <p>Telehealth has been especially advertised as a result of decentralizing the healthcare administrations and bringing patient-centered care outstandingly extremely close, however, writing on metropolitan satisfaction with the administrations delivered under telehealth in provincial areas is confined. Prior investigations yielded qualified results, most exceptionally savvy game plan.</p>
                        <p>While there are some investigates that express that telehealth may have a valuable result on use and patient results, there are many investigates that evident problems telehealth execution through describing explicit concerns, nonattendance of internet access, and patient inclinations against new innovations (Lee and Thompson, 2019).</p>
                        <p>Due to the above disparities, therefore there is a need to embrace a response based comparison to establish how much telemedicine has benefited for the healthcare organizations in the provincial district with one of theconstants being patient satisfaction levels.</p>
                        <h2>Significance of the Practice Problem</h2>
                        <p>The principles and practice of telehealth are right currently integrated into the advancement of health care in the cutting edge economy for individuals who are geographically dispersed, particularly in rural areas and need satisfactory offices for standard eye to eye attendances. There are a couple of basic aspects or factors that make the practice problem that the survey pivots around gigantic.</p>
                        <p>In this outline, the district that was considered was how useful telemedicine is as a practice in GSCs, or how much persistent satisfaction is seen in country settings, which is colossal in various ways. In any case, there are not adequate health care individual, insufficient health offices and ''popcorn'' HHS in provincial areas and lastly getting to health offices is maddening. This, therefore, explains why individuals from provincial areas are the more horrible off as far as health contrasted with those from metropolitan areas (Johnson and Green, 2019).</p>
                        <ul>
                            <li>
                                <h3>Telehealth's Impact on Patient Satisfaction</h3>
                            </li>
                        </ul>
                        <p>One issue that telehealth can address is the issue of administration transport that is often confined by things like region and this mean individuals living in distant areas can't get quality healthcare notwithstanding the straightforwardness of the innovation (Smith et al. , 2020).</p>
                        <p>What is basically truly concerning about this problem however is that examination has indicated that the level of satisfaction the patient has exercises an influence on the health of the said patient. Much more Tranquil Obligation, More prominent Patient Independence or self-administration and further made patient results associate distinctly with the Happiness levels of the patients (Lee and Thompson, 2019).</p>
                        <p>In requesting to add to an understanding of what makes telehealth work all through country provisions, it was likewise requisite to see which of the things pertaining to patients and how wide telehealth administrations are may add to the level of patient satisfaction.</p>
                        <h2>Quality Improvement Framework</h2>
                        <p>They are for the most part associated with telehealth and whether it prompts increased patient satisfaction in country health offices in the US. The telehealth administrations transport model applies the Quality Improvement (QI) Framework, which is a framework intended to update the quality of those administrations.</p>
                        <ul>
                            <li>
                                <h3>For this reason, the QI framework contains the standards provided below:</h3>
                            </li>
                        </ul>
                        <p>The following are the standard upheld procedures, with an emphasis on a consistent course of update and the use of proof in settings where ordinary health frameworks they are struggling with their work (Brown et al. , 2021). The following basic different framework works with different models. Of these, possibly, the best knows about known as action of the Plan Do-Study-Act (PDSA) development cycle. The advancements are made unendingly and checked from time to time in this cycle, it is hence informed by data, expressly for provincial health care needs (Garcia et al. , 2020).</p>
                        <p>The initial phase of the cycle is to see and plan. For telehealth, express tasks are defined in a bid to upgrade satisfaction from patients. A health care office could use this information to pick improving patient and care provider outlines, and other examinations on assistants of the health care office.</p>
                        <h3>NURS FPX 9904 Assessment 3 Doctoral Project Report</h3>
                        <p>In the 'Do' part, measures are applied in enhancing the teaching of health care professionals, acquisition of genuine and cutting-edge gadgets in telemedicine support and enhancing the patients' mindfulness on the existing projects and decisions. Their inspiration, as such, is to work on the overall satisfaction with the telehealth administrations among the patients and to interface what is seen as the gaps in administrations offered to the patients (Walker et al. , 2019).</p>
                        <p>The survey used numbers to establish things finding out issues, for instance, how satisfied patients are with the interventions; how a colossal piece of the time they are used and another information from patients and health care workers.</p>
                        <h2>Project Description</h2>
                        <p>The reason for the ongoing accommodating graduated class overview is to distinguish what aspects and results of telemedicine add to the level of satisfaction of patients in provincial areas with health care. Often, RHAs need HR, tremendous assets, and basically cash, which are critical for the office's functioning.</p>
                        <p>This is exasperated by the way that a greater piece of their patients are from the nation areas, and thriving in these districts have lower health results contrasted with metropolitan regions (Johnson and Green, 2019). Therefore, telemedicine can help diminish or try to eliminate such disparities in access, as it grants individuals an important opportunity to get clinical care without genuinely going somewhere. This suggests that the degree of the administrations is made easier for the individuals who live in distant areas (Smith et al., 2020).</p>
                        <p>The <a href="https://tutorsacademy.co/nurs-fpx-9904-assessment-3/">NURS FPX 9904 Assessment 3 Doctoral Project Report</a>&nbsp;examines these challenges, emphasizing the critical role of telehealth in bridging the healthcare access gap in rural areas. The main control of this coordinated method of employing mixed profound and quantitative thinking is to determine the level of satisfaction of clients in terms of telehealth administrations in country areas.</p>
                        <p>However, one of the health care quality markers is seen happiness, which ensures that patients continue to comply with their PCPs&rsquo; figures and achieve better health results (Lee and Thompson, 2019). The main idea of this project, therefore, remains indistinguishable from the original target of making patients splendid; this intervention attempts to gather more useful information pertaining to the handiness of telehealth innovation among individuals in provincial areas.</p>
                        <p>In particular, the review uses a mixed procedures research intent to attempt to show up at the following goals: only resulting to using a telehealth administration at a provincial health area, were asked to self-report relative satisfaction levels.</p>
                        <ul>
                            <li>
                                <h3>This is how quantitative data was gathered Quantitative data was collected in the following manner:</h3>
                            </li>
                        </ul>
                        <p>These outlines' inspiration is to determine how satisfied the patient is with the telehealth administration concerning its ease of course, administration or treatment, correspondence with healthcare professionals, and all around satisfaction with the given assistance (Garcia et al. , 2020). About the second procedure for data assortment, it is influential for explain that the data collecting cycle should be finished with extra profound interviews with patients and specialists. These would furnish you with a lot of insight on what they have gone through, their manner of thinking of the idea, and how the telehealth framework could be gotten to a more critical level.</p>
                        <h2>Project Evaluation Results</h2>
                        <p>The inspiration driving the review continued knowing how satisfied individuals are. The above findings on telemedicine in typical areas show that there are positive improvements that speedy observers and recipients to perceive that telemedicine is a realistic model of healthcare advancement pertinent to provincial districts.</p>
                        <p>The makers involved an online concentrate as a quantitative examination technique by which 250 patients who had taken part in telehealth in for sure at various country health centers finished self-administered outlines. There are concerns that episodes of Miserable may set off other disorders, including basic unforgiving episodes, bipolar disorder, strain, and comorbid pervasive formative disorders.</p>
                        <p>According to the numeric data analysis, an ordinary of 84% of clients indicated amazingly high satisfaction levels to video administrations. Patients likewise reported something that they cherished; it was the telemedicine, particularly home care as this eliminated the need and costs associated with driving (Smith et al. , 2020).</p>
                        <ul>
                            <li>
                                <h3>Telehealth Adoption in Rural Areas</h3>
                            </li>
                        </ul>
                        <p>Using how much individuals isolated by how much the audit individuals distributed two, the normal happiness score was 4. The concentrate likewise had a mean score of five from five which is an indication that individuals in the provincial district are willing to embrace the use of telehealth (Garcia et al. , 2020).</p>
                        <p>In the quantitative aspect of the investigation, both 30 patients and 15 healthcare administrators were interviewed using semi coordinated discussion. The following subjects were seen over the district interviews The following points were distinguished all through the area interviews a few subjects happened considering the locale interviews, as checked below.</p>
                        <p>The patients in the review respected the ease with which telehealth profits then to their health administrations providers and as well "telehealth made them more strong considering the way that instead of meeting in the office they were given extra time and continuous appointments to follow up their meetings" (Lee and Thompson, 2019).</p>
                        <p>A way that healthcare providers said that this transport model assisted attracted them to screen what is going on with the patients and give them care often. They likewise saw that clients with frozen shoulders Diabetic and other disorders that got them a lot of trouble mobilizing profited from the trampoline beds.</p>
                        <h2>Discussion and Implication for Nursing and Health Care</h2>
                        <p>Thus, from findings of the ongoing review, the nursing and healthcare cooperated administrations are shifting towards a more significant class of the nursing practice. This is happening when there is more centralization of standard eye to eye care administrations, and less in-person care is being gotten to, particularly in country areas.</p>
                        <p>Experiencing patient satisfaction that is significantly higher than whatever was found in this survey, telehealth has been viewed as a probable response for troubles associated with accessing healthcare in these areas.</p>
                        <ul>
                            <li>
                                <h3>The impact of telehealth delivered as intended has the following concerns:</h3>
                            </li>
                        </ul>
                        <p>Telehealth could change the normal manner through which nurses oversee patients. The main ideas that this paper will be based on about nurses' parts in telehealth are according to the following: Originally made to save costs, the nurses show the patients and oversee them by monitoring them through a video, clinical examinations, and involving patients in their care (Lee and Thompson, 2019). With progress in the use of telehealth, nurses ought to be trained and acclimated to fit the ordinary healthcare instruments basic to impel the improvement of telehealth to the patients.</p>
                        <ul>
                            <li>
                                <h3>Rural Health via Telehealth</h3>
                            </li>
                        </ul>
                        <p>Furthermore, telehealth licenses the nurses to be inventive and apply strategies originating from other fields in their practice. This is in light of the fact that; while nurses working in made areas need support from other professionals, for instance, specialists, pharmacists, social workers among others in deals to offer whole quiet care, those working in ordinary areas can easily gain assistance from them in requesting to offer total patient care (Walker et al. , 2019).</p>
                        <p>The main reason is that it is especially huge While undertaking cost decrease procedures, the variables that you truly need to determine are: whenever there are changes in costs then again assuming one is to set out on a cost decrease program, they should think about the following: The first is that the health care prerequisites of these clients that are residing in these provincial areas of plains are disappointed and require a strong energy.</p>
                        <p>Second, to ensure that every nation patient's health status is given the assertion it merits, thereby enhancing the quality of the health administrations being offered. Third, on the grounds enhancing the health of the provincial clients involves a breaking point concerning change that cravings to help a class of individuals residing in such places.</p>
                        <h2>Conclusion</h2>
                        <p>All around, took in an extraordinary blueprint about the opportunity of patient satisfaction in country health care setting involving telemedicine during my doctoral project. Based on the patient criticisms obtained in this survey, a huge piece of the patients said that they were especially satisfied with their telehealth helps thusly implying that telehealth could be used as a response for the healthcare related issues in the nation areas.</p>
                        <p>During various telehealth uses, underserved organizations can get better care, become more taken part in their own care, and gain an easier experiences with permission to care particularly in rural regions. A lot of tremendous problems should be settled before telehealth can be genuinely applied and used consistently in country areas.</p>
                        <p>An issue is that the infrastructure may not be eminent and on occasion, there may be network issues or bugs as for the UI of the innovation which diminishes the utility of telehealth administrations for patient satisfaction. Explore our assessment&nbsp;<span data-sheets-root="1">NU<a href="https://onlinecourseservices.us/nurs-fpx-9904-assessment-1-cbt-sessions">RS FPX 9904 Assessment 1 CBT Sessions and Motivational Intervening</a>&nbsp;for more information about this class.</span></p>
                        <h2><strong>References</strong></h2>
                        <p>Anderson, K. M., McLaughlin, M. K., Crowell, N. A., Fall-Dickson, J. M., White, K. A., Heitzler, E. T., Kesten, K. S., &amp; Yearwood, E. L. (2019). Mentoring students engaging in scholarly projects and dissertations in doctoral nursing programs.&nbsp;<em>Nursing Outlook</em>,&nbsp;<em>67</em>(6), 776&ndash;788.</p>
                        <p><a href="https://doi.org/10.1016/j.outlook.2019.06.021" target="_blank" rel="noopener">https://doi.org/10.1016/j.outlook.2019.06.021</a></p>
                        <p>Cygan, H. R., &amp; Reed, M. (2019). DNP and phd scholarship: Making the case for collaboration.&nbsp;<em>Journal of Professional Nursing</em>,&nbsp;<em>35</em>(5).</p>
                        <p><a href="https://doi.org/10.1016/j.profnurs.2019.03.002" target="_blank" rel="noopener">https://doi.org/10.1016/j.profnurs.2019.03.002</a></p>
                        <p>Dobrowolska, B., Chruściel, P., Pilewska-Kozak, A., Mianowana, V., Monist, M., &amp; Palese, A. (2021). Doctoral programmes in the nursing discipline: A scoping review.&nbsp;<em>BMC Nursing</em>,&nbsp;<em>20</em>(1).</p>
                        <p><a href="https://doi.org/10.1186/s12912-021-00753-6" target="_blank" rel="noopener">https://doi.org/10.1186/s12912-021-00753-6</a></p>
                        <p>Jackman, P. C., Jacobs, L., Hawkins, R. M., &amp; Sisson, K. (2021). Mental health and psychological wellbeing in the early stages of doctoral study: A systematic review.&nbsp;<em>European Journal of Higher Education</em>,&nbsp;<em>12</em>(3), 1&ndash;21.</p>
                        <p><a href="https://doi.org/10.1080/21568235.2021.1939752" target="_blank" rel="noopener">https://doi.org/10.1080/21568235.2021.1939752</a></p>
                        <p>Moran, K. J., Burson, R., &amp; Conrad, D. (2023). The doctor of nursing practice project: A framework for success. In&nbsp;<em>Google Books</em>. Jones &amp; Bartlett Learning.</p>
                        <p><a href="https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=0f%20pEAAAQBAJ&amp;oi=fnd&amp;pg=PP1&amp;dq=Doctoral+Project+Report+in+healthcare&amp;ots=bfDInsTfuK&amp;sig=_fb_LurTS0rMP86gCoYxugKGmn0&amp;redir_esc=y#v=onepage&amp;q=Doctoral%20Project%20Report%20in%20healthcare&amp;f=false" target="_blank" rel="noopener">https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=0f pEAAAQBAJ&amp;oi=fnd&amp;pg=PP1&amp;dq=Doctoral+Project+Report+in+healthcare&amp;ots=bfDInsTfuK&amp;sig=_fb_LurTS0rMP86gCoYxugKGmn0&amp;redir_esc=y#v=onepage&amp;q=Doctoral%20Project%20Report%20in%20healthcare&amp;f=false</a></p>
                        <p>Roush, K. (2023). A nurse&rsquo;s step-by-step guide to writing A dissertation or scholarly project, third edition. In&nbsp;<em>Google Books</em>. Sigma Theta Tau.</p>
                        <p><a href="https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=qk2tEAAAQBAJ&amp;oi=fnd&amp;pg=PT2%20&amp;dq=Doctoral+Project+Report+in+healthcare&amp;ots=YMf5IJiGZW&amp;sig=57Xij4DBRdhlSJ0dnM_BrexL51M&amp;redir_esc=y#v=onepage&amp;q&amp;f=false" target="_blank" rel="noopener">https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=qk2tEAAAQBAJ&amp;oi=fnd&amp;pg=PT2 &amp;dq=Doctoral+Project+Report+in+healthcare&amp;ots=YMf5IJiGZW&amp;sig=57Xij4DBRdhlSJ0dnM_BrexL51M&amp;redir_esc=y#v=onepage&amp;q&amp;f=false</a></p>
                        <p>Tomlinson, J., Medlinskiene, K., Cheong, V-Lin., Khan, S., &amp; Fylan, B. (2019). Patient and public involvement in designing and conducting doctoral research: The whys and the hows.&nbsp;<em>Research Involvement and Engagement</em>,&nbsp;<em>5</em>(1).</p>
                        <p><a href="https://doi.org/10.1186/s40900-019-0155-1" target="_blank" rel="noopener">https://doi.org/10.1186/s40900-019-0155-1</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx9904assessment3