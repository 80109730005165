import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const DNP830Module3Assignment = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>DNP 830 Module 3 Assignment Methodology</title>
                <meta name='description'
                    content='Explore the DNP 830 Module 3 Assignment Methodology and master essential research strategies. Start your journey now!' />
                <meta name='keywords' content='DNP 830 Module 3 Assignment Methodology' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>DNP 830 Module 3 Assignment < br /><span>Methodology</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/dnp830module3assignment.webp" alt="DNP 830 Module 3 Assignment" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>DNP 830 Module 3 Assignment Methodology.</em></strong></li>
                        </ul>
                        <h2><strong>Chapter 3: Methodology</strong></h2>
                        <p>The approach utilized in this quality improvement project is a controlled preliminary with two group members chosen randomly. This will include the improvement of a diabetes management toolbox and two groups of members; one gets the diabetes management tool stash while the other gets regular consideration without the tool compartment.</p>
                        <p>The distinguished parts of the diabetes management tool compartment might incorporate dietary rules for patients with diabetes, suggestions for actual work, drug regimens, and adherence devices, as well as literacy schooling instruments expected to work on diabetes self-management (Kalra et al., 2020).</p>
                        <p>Patients will be helpfully enlisted from short-term facilities in rustic and metropolitan settings and will probably be determined to have type 2 diabetes with an A1c estimation at the baseline. Enlistment will incorporate a cycle that includes contact with practitioners who will distinguish qualified patients to concentrate on enlistment. The A1c levels of each member will be taken at the underlying and after the fruition of the mediation, which is a half year utilizing the tool stash.</p>
                        <p>The following produce procedures for assessment and assessment will be utilized by the project to guarantee the high viability of the tool stash: First, the review will utilize an observing framework that spotlights getting predictable data on members' interactions with the recognized tool compartment parts (Kalra et al., 2020). This will incorporate the degree to which the patients utilize an application on their cell phones and how frequently they finish a sign-in box about their eating regimen and prescriptions, which must be filled over the day.</p>
                        <p>The members will manage organized questionnaires and interviews consistently, which will assist in collecting subjective data concerning the members' encounters and any difficulties caused by using the tool stash (Harris and Brown, 2019). This feedback will be utilized to roll out steady improvements that will be made to the tool stash during the time for testing to work on the apparatus additionally.</p>
                        <p>The appraisal of the project will be finished after breaking down the outcomes from the different quantitative and subjective evaluations. Utilizing a quantitative exploration approach, examination of the distinction in A1c in the two groups of patients, the mediation group and the benchmark group, will have comparative statistics dissected to decide huge, unique suggestions for clinical practice (Sharpless et al., 2021).</p>
                        <p>In quantitative Analysis, descriptive statistics of recurrence appropriation about members' discernments will be dissected thematically to investigate the likely ease of use of the tool compartment and its expected advantages.</p>
                        <h2><strong>Project Design</strong></h2>
                        <p>The strategy investigated and proposed for this project is a semi-trial study with non-comparable benchmark groups to survey the clinical tool compartment for diabetes management (Pamungkas and Chamroonsawasdi, 2020). It is intended to be applied to patients with type 2 diabetes to increase glycated hemoglobin A1c levels. The patients will be randomly chosen from various short-term diabetes centers and divided into two groups.</p>
                        <p>The ongoing review will have an intercession group that will be furnished with the diabetes management tool stash through which they will be equipped with work on dietary management, recommended actual activity system, prescription updates, and other educative material intended to assemble their diabetic handling information (Smith et al., 2023). The benchmark group will happen with their treatment as they ordinarily would, with no additional methods being performed on them.</p>
                        <ul>
                            <li>
                                <h3><strong>Enrollment and Intervention Process&nbsp;</strong></h3>
                            </li>
                        </ul>
                        <p>The methodology for the execution of the project will start with the enrollment of members who meet the consideration models: patients having Type 2 diabetes and their A1c level is over 7% (MacPherson et al., 2021). The people who meet the consideration rules will be approached and given definite data about the motivation and cycle of the review. Upon their composed permission, they will take part in the review.</p>
                        <p>The tool stash is a gathering of a few mediation devices that will be clarified for the members of the mediation group during the underlying meeting. Potential digital apparatuses are shown to the members, and the materials are circulated.</p>
                        <h3><strong>DNP 830 Module 3 Assignment</strong></h3>
                        <p>The mediation and the control will be followed up after one month in the following half year, consistently filling A1c levels and practices concerning diabetic management. The two groups will be followed for quite some time; month-to-month interviews will be directed to catch changes in A1c, as well as examples of practice, including diabetes management, changes in the way of life, and drugs.</p>
                        <p>Essential data collection methods incorporate the utilization of questionnaires and direct perception of the build being estimated. The fundamental review test estimates will be taken at baseline and toward the end of the review to consider the viability of the mediation and A1c levels.</p>
                        <p>Further, segment data, explanations behind the visiting facility, and subtleties of members' interaction with the tool stash and its utilization of the diabetes management plan will be accumulated by utilizing self-regulated daily journals and electronic logs coordinated inside the tool compartment (Kim et al., 2019). The impact of the mediation will be surveyed by looking at the A1c distinction between the taking part and non-intercession groups, thus applying factual strategies to evaluate the planned incentive for the noticed change in contrasts.</p>
                        <h2><strong>Instrumentation</strong></h2>
                        <p>For the project that arrangements with the evaluation of a created diabetes management tool compartment and its impact on glycated hemoglobin (A1c) levels, the decision of instrumentation, that is, devices that are utilized during the time spent data get-together will be vital because the legitimate evaluation of results will require appropriate data collection devices. The key instruments used will include:</p>
                        <p>The glycated hemoglobin (HbA1c) test packs will be utilized to decide the member's A1c levels before the initiation of the preliminary and after the half-year intercession (Pohanka, 2021). The A1c test arranges the image of the typical glucose control in roughly three months and is a significant apparatus in dealing with the condition. Versatile self-observation and well-being management applications will support tracking the admission of food varieties, working out, remedy consistency, and screening blood glucose levels. These applications will make an equilibrium and give continuous data that can accessed by both the members and the medical services suppliers to evaluate consistency and change the therapy plan (Pohanka, 2021).</p>
                        <p>For the <strong>DNP 830 Module 3 Assignment Methodology</strong>, physiological monitoring devices will be used only for volunteer participants. We might utilize portable monitoring devices that record participants' physiological parameters, such as physical activity and blood pressure, at regular intervals (Block et al., 2020). This data will be valuable in evaluating the consistency of work recommendations provided by the unimplemented tool kit. These instruments will be integrated into the study to collect a rich data set necessary for analyzing the identified tool kit and its potential impact on A1C levels among the target population of adults with type 2 diabetes.</p>
                        <ul>
                            <li>
                                <h3><strong>Data Collection</strong></h3>
                            </li>
                        </ul>
                        <p>The data collection for this quality improvement project pointed toward evaluating the utility of a diabetes management tool compartment, which will hence be purposive and deliberate to catch each significant part of the advantage of the tool compartment on the members' glycated hemoglobin (A1c) and other clinical characteristics. The data collection interaction will include the following parts:</p>
                        <ul>
                            <li>
                                <h3><strong>Baseline and Follow-up A1c Measurements</strong></h3>
                            </li>
                        </ul>
                        <p>Fasting A1c tests will be accumulated from every one of the members to keep an eye on their own A1c values before getting into the review and after the half-year for testing. These measurements will be completed following normal put research center practices together to acquire accurate and solid outcomes on the diabetes management instruments that we create (Block et al., 2020).</p>
                        <ul>
                            <li>
                                <h3><strong>Digital Logging and Tracking</strong></h3>
                            </li>
                        </ul>
                        <p>The mediation group will take part in day-to-day documentation of activities in connection with diabetes utilizing the devices consolidated in the tool stash. This likewise includes noticing what the patient eats regarding food, their activities, portions of the drugs they take, and their blood glucose levels. These journals will yield mathematical data, which will help decide how stringently the patient has followed the diabetes management plan and whether the progressions are normal on A1c levels.</p>
                        <ul>
                            <li>
                                <h3><strong>Surveys and Questionnaires</strong></h3>
                            </li>
                        </ul>
                        <p>Throughout the review period, members will be provoked to fill out questionnaires and surveys to collect data on their satisfaction, the adequacy and usability of the tool stash, and any changes to their well-being-related personal satisfaction (Block et al., 20wellbeing data will supplement the quantitative data.</p>
                        <ul>
                            <li>
                                <h3><strong>Interviews</strong></h3>
                            </li>
                        </ul>
                        <p>Hence, the inquiries with the end goal of subjective data collection will be semi-organized and directed midway through and toward the finish of the review with some of the members. These engaged interviews will include breaking down the challenges that occurred and seeing the benefits of applying the tool stash in the management of diabetes by the members. These interviews will be viable in distinguishing some areas that can be centered around while fostering this tool stash.</p>
                        <ul>
                            <li>
                                <h3><strong>Device Data Collection</strong></h3>
                            </li>
                        </ul>
                        <p>Wearable devices for similar members will collect objective data on active work and other physiological characteristics, assuming the member assents (Vijayan et al., 2021). Data recorded from these devices would give additional information about members' activity levels and what such levels can mean for their A1d.</p>
                        <p>Involving these data collection methods in the mix implies that it will be feasible to extensively decide what the diabetes management tool compartment means for A1c and other significant measurements connected with diabetes. The applied approach to integrating quantitative and subjective data will likewise upgrade our understanding of the clients' encounters and the day-to-day practice of utilizing a tool stash of this sort (Vijayan et al., 2021).</p>
                        <ul>
                            <li>
                                <h3><strong>Data analysis methods</strong></h3>
                            </li>
                        </ul>
                        <p>To assess the discoveries assembled from the review pointed toward evaluating the effectiveness of a diabetes management tool stash, both quantitative and subjective strategies of Analysis will be utilized. Here are the primary data analysis methods that will be used:</p>
                        <p>Descriptive Statistics: Data analysis will start with a data outline where an endeavor will be made to portray the gathered data utilizing simple statistics that depict the qualities, focal propensity (Terrell, 2021), and scattering of the data gathered. This incorporates computing, which implies medians and standard deviations for variables that can be constant, for example, the A1c levels and frequencies, and rates where suitable for the factors, the adherence rates, and the study reactions. This step will assist with deciding the overall concavity or convexity of the thickness capability and the mean of the reliant variable in the mediation and, all the more so, the benchmark group.</p>
                        <h4><em>Comparative Analysis:</em></h4>
                        <p>to look at the viability of the diabetes management tool compartment, a matched T-trial of the essential result of this study will be performed, which is the distinction of A1c at baseline and the half-year follow-up visit. The fundamental Analysis will include the utilization of autonomous t-tests (ordinary dispersion).</p>
                        <p>At the same time, Mann-Whitney U-tests for non-typical dissemination data sets will be utilized to correlate contrasts between the two groups (Terrell, 2021). Further, Analysis of covariance (ANCOVA) can be used to control for any abnormality and similarly or inconsistently adjusted frustrating factors at the baseline.</p>
                        <h4><em>Regression Analysis:</em></h4>
                        <p>Once more, the scientific device that will be utilized to lay out the connection between the free factors, for example, the degree to which members accumulated with the tool compartment, their degree of cooperation, and other segment characteristics of the members and the degree of progress in A1c levels as the reliant variable not entirely set in stone through different regression analysis. This Analysis will help decide how explicitly powerful the other factors are in upgrading the diabetic populace's management of infection (Terrell, 2021).</p>
                        <h4><em>Time Series Analysis:</em></h4>
                        <p>If data is acquired over the long run, for instance, data from wearable devices consistently recording data throughout some time, then time series analysis will be utilized to investigate the data and decide on some fluctuation designs (Vijayan et al., 2021). This will help establish whether there is a massive contrast between the recurrence of the purpose of the tool stash and variance in A1c levels over the period under study.</p>
                        <h4><em>Thematic Analysis:</em></h4>
                        <p>Interviews and inquiries that could go either way posed in the surveys will be coded thematically to decide on shared examples and stories, and the members will answer emphatically about the tool stash on diabetes. This sort of Analysis is significant as it assists with recognizing various perspectives connected with convenience, the advantages of the members, and potential boundaries while participating in the activity talked about, which probably won't be reflected by quantitative data (Vijayan et al., 2021).</p>
                        <h4><em>Sensitivity Analysis:</em></h4>
                        <p>To be more sure of the outcomes that will be obtained in the review, the critical suspicions and boundaries that will be utilized to show up at the outcomes will be changed, and the outcomes will relapse to look at the sensitivity of the review. This will assist the audits with recognizing the impact of the predispositions to the outcomes and help to know the unwavering quality of the outcomes and their other convenience in different events.</p>
                        <p>By utilizing these different data analysis approaches, this study tries to convey a broad and observational evaluation of whether the execution of an accessible diabetes management tool stash can build the grown-up persistent's glycated hemoglobin levels with T2DM, finally prompting the distinguishing proof of the tool stash's practicality and appropriateness.</p>
                        <h2>Data Management Methods</h2>
                        <p>Names and other recognizable proof subtleties of the patients and different partners engaged with the review concerning the impact of a diabetes management tool compartment will be appropriately anonymized and gotten to keep up with the secrecy of the data gathered through the data collection, capacity, Analysis, and detailing process.</p>
                        <h3>DNP 830 Module 3 Assignment</h3>
                        <p>First, data will be extracted through a few sources, including self-revealed A1c test results, self-observed blood glucose records obtained from digital tracking applications and wearable devices, and questionnaires completed by members. It is suggested that each arrangement of data will be given a distinguishing proof number to get the obscurity of the members. All particular and general data will be kept in a secret phrase-safeguarded database, with clients limited to the exploration group.</p>
                        <ul>
                            <li>
                                <h3><strong>Data Integrity and Validation</strong></h3>
                            </li>
                        </ul>
                        <p>This will help guarantee that the data is correct and complete, as a backup will be taken regularly to backup data in the event of any deficiency. Each tracking apparatus is ordinarily designed with choices for data approval, and this will assist with taking out the possibility of entering mistaken figures in the framework.</p>
                        <p>For instance, incorporating values for blood glucose levels higher or lower than any doable candidates will provoke an alarm-affirming check. Further, any progressions made to the data will be time-stepped and archived with the client required to support venture-wide ease of use (Vijayan et al., 2021). This thorough approach assists with moderating the gamble and is an action that confirms that the data in the last Analysis is sound and can be traced back to its source in case of a question.</p>
                        <h2><strong>Ethical Considerations</strong></h2>
                        <p>The exploration proposition manages the evaluation of a diabetes management tool compartment. While directing this review, explicit ethical issues should be seen to safeguard the members and keep up with the validity of the discoveries (Pietil&auml; et al., 2020). Here are the critical ethical considerations for this project: Here are the vital ethical considerations for this project:</p>
                        <p>Both exploration and control subjects should get consent from the scientist before joining the review. It might incorporate the ID of the targets and points of the review, why individuals are being looked for interest, the potential dangers that might emerge, and the likely gains of the review. Another perspective is that members ought to be mindful that support is deliberate and there are no repercussions for their cooperation, assuming they withdraw from the review at some random time without affecting future consideration.</p>
                        <p>In this way, it is crucial to guarantee that any data obtained from the members is kept as rigorously secret as possible. This implies that all data should be put away to the best of the accessible standard and be accessible by the faculty who have doled out the option to make changes to it. We suggest that recognizable data be taken out by anonymization or de-distinguishing proof before Analysis to guarantee members can't be distinguished from the outcomes (Pietil&auml; et al., 2020).</p>
                        <h2><strong>Conclusion</strong></h2>
                        <p>The potential for improvement in the current trends in diabetes treatment and the utilization of a comprehensive diabetes management toolkit in assessing the efficacy of glycated hemoglobin (A1c) level reduction in adult patients with type 2 diabetes make the project unique and worth exploring further. This approach of providing a structured toolkit, such as a dietary guide, exercise regimen, medication requisites, and health education module, intends to equip the patients with the requisite knowledge and management ability for the ailment.</p>
                        <p>This study not only brings value to academics as it yields findings that have been either theoretical or experimental, but it is also in tune with the concept of improving the quality of life for people living with a condition like type 2 diabetes. In alignment with the objectives of the DNP 830 Module 3 Assignment Methodology, this approach emphasizes the importance of integrating evidence-based practices to develop a comprehensive toolkit for managing diabetes. The implementation of this toolkit, if successful, has the potential to become a cornerstone for improving patient care and outcomes in clinical settings.</p>
                        <h2><strong>References</strong></h2>
                        <p>Brick, T. R., Mundie, J., Weaver, J., Fraleigh, R., &amp; Oravecz, Z. (2020). Low-burden mobile monitoring, intervention, and real-time analysis using the wear-it framework: Example and usability study. <em>JMIR Formative Research</em>, <em>4</em>(6), e16072. <a href="https://doi.org/10.2196/16072">https://doi.org/10.2196/16072</a></p>
                        <p>Harris, L., &amp; Brown, G. (2019). Mixing interview and questionnaire methods: Practical problems in aligning data. <em>Practical Assessment, Research, and Evaluation Practical Assessment</em>, <em>15</em>(1). <a href="https://scholarworks.umass.edu/pare/vol15/iss1/1/">https://scholarworks.umass.edu/pare/vol15/iss1/1/</a></p>
                        <p>Kalra, S., Bajaj, S., Sharma, S. K., Priya, G., Baruah, M. P., Sanyal, D., Das, S., Chaudhury, T., Gangopadhyay, K. K., Das, A. K., Sethi, B., Ayyar, V., Shaikh, S., Shah, P., Jindal, S., Deshmukh, V., Dave, J., Amod, A., Joshi, A., &amp; Pokharel, S. (2020). A practitioner&rsquo;s toolkit for insulin motivation in adults with type 1 and type 2 diabetes mellitus: Evidence-based recommendations from an international expert panel. <em>Diabetes Therapy</em>, <em>11</em>(3), 585&ndash;606. <a href="https://doi.org/10.1007/s13300-020-00764-7">https://doi.org/10.1007/s13300-020-00764-7</a></p>
                        <p>Kim, Y.-S., Kim, H.-S., &amp; Kim, Y.-L. (2019). Effects of a web-based self-management program on the behavior and blood glucose levels of women with gestational diabetes mellitus. <em>Telemedicine and E-Health</em>, <em>25</em>(5), 407&ndash;414. <a href="https://doi.org/10.1089/tmj.2017.0332">https://doi.org/10.1089/tmj.2017.0332</a></p>
                        <p>MacPherson, M. M., Cranston, K. D., Locke, S. R., Bourne, J. E., &amp; Jung, M. E. (2021). Using the behavior change wheel to develop text messages to promote diet and physical activity adherence following a diabetes prevention program. <em>Translational Behavioral Medicine</em>, <em>11</em>(8), 1585&ndash;1595. <a href="https://doi.org/10.1093/tbm/ibab058">https://doi.org/10.1093/tbm/ibab058</a></p>
                        <p>Pamungkas, R. A., &amp; Chamroonsawasdi, K. (2020). Self-management based coaching program to improve diabetes mellitus self-management practice and metabolic markers among uncontrolled type 2 diabetes mellitus in Indonesia: A quasi-experimental study. <em>Diabetes &amp; Metabolic Syndrome: Clinical Research &amp; Reviews</em>, <em>14</em>(1), 53&ndash;61. <a href="https://doi.org/10.1016/j.dsx.2019.12.002">https://doi.org/10.1016/j.dsx.2019.12.002</a></p>
                        <p>Pietil&auml;, A.-M., Nurmi, S.-M., Halkoaho, A., &amp; Kyng&auml;s, H. (2020). Qualitative research: Ethical considerations. <em>The Application of Content Analysis in Nursing Science Research</em>, <em>1</em>(1), 49&ndash;69. <a href="https://link.springer.com/chapter/10.1007/978-3-030-30199-6_6">https://link.springer.com/chapter/10.1007/978-3-030-30199-6_6</a></p>
                        <p>Pohanka, M. (2021). Glycated hemoglobin and methods for its point of care testing. <em>Biosensors</em>, <em>11</em>(3), 70. <a href="https://doi.org/10.3390/bios11030070">https://doi.org/10.3390/bios11030070</a></p>
                        <p>Sharpless, E., Borkowski, N., O&rsquo;Connor, S. J., Hearld, L., &amp; Szychowski, J. (2021). A comparison of two diabetes self-management education programs for the reduction of participant A1c Levels. <em>American Journal of Health Promotion</em>, <em>35</em>(7), 988&ndash;990. <a href="https://doi.org/10.1177/08901171211003829">https://doi.org/10.1177/08901171211003829</a></p>
                        <p>Smith, K. A., Van Pinxteren, M., Mbokazi, N., Majikela-Dlangamandla, B., Delobelle, P., Levitt, N., &amp; McGrath, N. (2023). Intervention development of &ldquo;Diabetes Together&rdquo; using the person-based approach: a couples-focused intervention to support self-management of type 2 diabetes in South Africa. <em>BMJ Open</em>, <em>13</em>(5), e069982. <a href="https://doi.org/10.1136/bmjopen-2022-069982">https://doi.org/10.1136/bmjopen-2022-069982</a></p>
                        <p>Terrell, S. R. (2021). Statistics translated: A step-by-step guide to analyzing and interpreting data. In <em>Google Books</em>. Guilford Publications. <a href="https://books.google.com/books?hl=en&amp;lr=&amp;id=gbb2DwAAQBAJ&amp;oi=fnd&amp;pg=PP1&amp;dq=Data+analysis+will+commence+with+data+summarization+where+an+attempt+will+be+made+to+describe+the+collected+data+using+merely+statistics+that+describe+the+values">https://books.google.com/books?hl=en&amp;lr=&amp;id=gbb2DwAAQBAJ&amp;oi=fnd&amp;pg=PP1&amp;dq=Data+analysis+will+commence+with+data+summarization+where+an+attempt+will+be+made+to+describe+the+collected+data+using+merely+statistics+that+describe+the+values</a></p>
                        <p>Vijayan, V., Connolly, J. P., Condell, J., McKelvey, N., &amp; Gardiner, P. (2021). Review of wearable devices and data collection considerations for connected health. <em>Sensors (Basel, Switzerland)</em>, <em>21</em>(16), 5589. <a href="https://doi.org/10.3390/s21165589">https://doi.org/10.3390/s21165589</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default DNP830Module3Assignment
