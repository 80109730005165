import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx6030assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 6030 Assessment 2 | Stroke Care Improvement</title>
                <meta name='description'
                    content='Discover how NURS FPX 6030 Assessment 2 introduces training for timely stroke care, improving response times and hospital health outcomes.' />
                <meta name='keywords' content='NURS FPX 6030 Assessment 2 Problem Statement (PICOT)' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 6030 Assessment 2 < br /><span>Problem Statement (PICOT)</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/nursfpx6030assessment2.webp" alt="NURS FPX 6030 Assessment 2 Problem Statement (PICOT)" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><span data-preserver-spaces="true"><em><strong>NURS FPX 6030 Assessment 2 Problem Statement (PICOT).</strong></em><br /></span></strong></li>
                        </ul>
                        <h2><strong><span data-preserver-spaces="true">Need Statement</span></strong></h2>
                        <p><span data-preserver-spaces="true">In-hospital strokes, which account for nearly one in five strokes, present a pressing need for immediate attention (Yang et al., 2019). Patients admitted to a stroke-specific unit from a community-onset stroke generally fare better than in-hospital stroke patients, who often experience worse outcomes and more severe strokes (Green et al., 2021). Numerous studies have shown that in-hospital stroke patients face significant delays in care, leading to increased length of stay, disability, and mortality (Kassardjian et al., 2017). </span></p>
                        <p><span data-preserver-spaces="true">The complexity of hospital patients' comorbidities can make stroke recognition even more challenging (Yang et al., 2019). In-hospital stroke patients must receive the same level of care and prompt attention as those admitted to a specialized care unit (Green et al., 2021). Therefore, training nurses to recognize stroke in their in-hospital patients is important and urgent.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Population and Setting</span></strong></h2>
                        <p><span data-preserver-spaces="true">This needs assessment is for a level one trauma center with over 800 beds and a designated stroke unit that offers specialized stroke care. However, since COVID-19, the bed shortage has made it so patients are placed on floors where beds are available and not necessarily on a unit specific to their needs. For example, an orthopedic floor may accept patients from the Emergency Department with various morbidities. While orthopedic nurses may know how to care for Med-Surg patients, this is not the patient population they are used to. </span></p>
                        <p><span data-preserver-spaces="true">There is also the added stress of short staffing due to the pandemic. Nurses in general units are inexperienced with stroke best practices, which is a barrier to providing effective treatment to patients with in-hospital stroke (Green et al., 2021). Nurses who are not trained to recognize stroke signs and symptoms may miss an in-hospital stroke on their patients.</span></p>
                        <p><span data-preserver-spaces="true">The Cardiovascular Testing Center (CVTC) is an ambulatory center within the hospital that performs procedures on both inpatients and outpatients. Most of the patients treated have atrial fibrillation (A-Fib) and are at an increased risk for stroke. Nearly a quarter of ischemic stroke patients have a history of A-Fib, and these patients typically have more severe strokes (Perera et al., 2018).</span></p>
                        <h3><span data-preserver-spaces="true">NURS FPX 6021 Assessment 2 Change Strategy and Implementation</span></h3>
                        <p><span data-preserver-spaces="true">Ambulatory nurses may not have experience taking care of stroke patients and, if not properly trained, may miss a stroke on their patients. A potential challenge of working on the identified need with the target population of nurses in inpatient and outpatient settings is working with their busy schedules to educate them on stroke recognition. Due to staff shortages, many nurses are stretched thin and may be reluctant to take time from patient care to be in-serviced as they feel it will make them even more behind. The institution will follow the Plan Do Study Act (PDSA) model to address these challenges to implement the education program. This model helps develop defined goals and assess whether the adjustments improve the situation (Tichnor-Wagner et al., 2017). </span></p>
                        <p><span data-preserver-spaces="true">The first step in the PDSA cycle includes the plan for the change that will be implemented, which begins with a clearly defined and measurable goal (Mahoney &amp; Simmonds, 2020). Next is the Do stage&mdash;carrying out the quality improvement change, which involves gathering data to determine the scope of the problem and identify areas of good practice and areas where improvements are needed (Mahoney &amp; Simmonds, 2020). </span></p>
                        <p><span data-preserver-spaces="true">Next is the Study stage- Once the change has been implemented, the quantifiable outcomes are examined, data is collected before and after the change, and consideration is given to the change's impact and lessons learned (Mahoney &amp; Simmonds, 2020). The final stage is the Act stage, how the change will be fully implemented (Tichnor-Wagner et al., 2017). By following this model, we can ensure the effectiveness of the program and its successful implementation.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Intervention Overview</span></strong></h2>
                        <p><span data-preserver-spaces="true">Rapid recognition, diagnosis, and timely evidence-based therapy are the goals of quality stroke care (Green et al., 2021). Lack of education about recognizing stroke in patients, delayed notification of the most appropriate staff, and poor communication about the need for immediate medical evaluation are some of the reported causes of delays in stroke intervention (Kassardjian et al., 2017). An educational intervention for nurses, however, offers a ray of hope. It would enhance inpatient stroke first response time and quick access to innovative interventional treatments for acute stroke (Kassardjian et al., 2017).</span></p>
                        <p><span data-preserver-spaces="true"> Education is needed to close the knowledge gap for nurses on non-stroke specialized units and ambulatory nurses caring for patients at high risk for stroke. Implementing an educational program on recognizing stroke signs and symptoms will decrease the time from stroke onset to a code stroke, thereby ensuring the patient receives appropriate intervention to reduce stroke severity.</span></p>
                        <h3><span data-preserver-spaces="true">NURS FPX 6021 Assessment 2 Change Strategy and Implementation</span></h3>
                        <p><span data-preserver-spaces="true">Education on the steps to take after recognizing stroke symptoms (a stroke algorithm) will close the knowledge gap on the proper management of in-hospital stroke patients. The Stroke Core course will be taught in a series of in-person classes taught by members of the nursing education department. These classes, including Stroke and the Inpatient Nurse, Stroke-What Every Nurse Needs to Know, Neuro Assessment, and Ischemic Stroke and Hemorrhagic Stroke-Emergent Care, promise to equip nurses with the knowledge and skills they need to provide better care and improve patient outcomes.</span></p>
                        <p><span data-preserver-spaces="true">Stroke is one of the most common diseases that affects 25% of the population in their lifetime (Buck et al., 2021). It is not uncommon for nurses to be caring for a patient who will have a stroke while under their care. The stroke education intervention fits nurses on non-stroke specific units well because their patients may have comorbidities that make it difficult to recognize a stroke in their patients. Hypoglycemia, migraine headaches, dehydration, seizures, or dementia may make it challenging to identify a stroke (Yang et al., 2019).</span></p>
                        <p><span data-preserver-spaces="true"> Ambulatory nurses working in the CVTC have patients with A-Fib, which puts patients at high risk of stroke. The education intervention will, therefore, benefit this target population and setting. A weakness in the intervention is that education is not available to many nurses within the hospital. They may not understand the importance of educating themselves or feel they already understand how to recognize a stroke and the code stroke protocols. The importance of receiving education needs to be emphasized.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Comparison of Approaches&nbsp;</span></strong></h2>
                        <p><span data-preserver-spaces="true">An alternative inter-professional intervention focused on solving the non-recognition of stroke&nbsp;</span></p>
                        <p><span data-preserver-spaces="true">symptoms may be to offer the class to other disciplines other than nurses. Since patients interact with many non-nurse staff members, education may help non-nurses recognize a stroke. This approach encourages inter-professional care. This approach considers that the organization should acknowledge that non-clinical staff members will not have the same knowledge base as nurses. An alternative approach may be to have a separate education class for non-clinical personnel. Educating all staff members on the signs and symptoms of stroke ensures our patients receive the highest quality of care.&nbsp;</span></p>
                        <p><span data-preserver-spaces="true">Currently, the organization has a dedicated stroke resource team that responds to code strokes. The team comprises a neurologist, a</span><span data-preserver-spaces="true">respiratory therapist, a mid-level, and an ED charge nurse. The education intervention for cardiovascular nurses has the potential to enhance the current stroke code by presenting the patient to the code team with less from Time Last Known Well (TKLW) to a code stroke being called. TLKW is when the patient was last known to be without signs or symptoms of the current stroke. The cardiovascular nurses will be an extra set of eyes for the code team. The education provided will help the nurses recognize the signs, call a code stroke sooner, and give the stroke code team a better chance to deliver timely patient care, leading to better health outcomes.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Initial Outcome Draft</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">The Stroke Core course aims to improve stroke recognition for non-stroke unit-specific nurses and ambulatory nurses in the Cardiovascular Testing Center. The goal is for knowledge of stroke recognition to improve by at least 20%, as shown by pre/post-knowledge tests; thereby, the time from stroke recognition to code stroke being called will decrease systemwide. Criteria that can be used to evaluate the achievement of a systemwide decrease in time to code stroke is that the timing will better align with the recognition of stroke-specific units, which is currently between two and six minutes. With the Stroke Core education classes, the non-stroke-specific units will have the goal of a code stroke time the same as stroke-specific units, ensuring all patients receive the best health outcomes.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Time Estimate</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">The time frame for developing and implementing the intervention will be six months. The nursing education department must research evidence-based data to create the curriculum for the Stroke Core classes. The curriculum development process will be done over the first two months. Once the curriculum is developed, pilot classes will be conducted. Then, the Stroke Core courses will be taught over three months, and the classes will be offered at different times across the three months to ensure the highest participation. Knowledge gaps affecting the time frame include whether the nursing education department will have the time to conduct the research and create the curriculum and if three months is enough time for most nurses to take the required courses.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Literature Review</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">This literature review aims to validate the need for stroke recognition for non-stroke-specific unit nurses at the Level-one trauma hospital. The scope of the literature review will include scholarly writing from the past five years. This literature review aims to draw upon the insight from the literature and evaluate and synthesize the resources to ensure the intervention will produce the best health outcomes for the patients.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">The Need&nbsp;</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">According to the World Stroke Organization (WSO, 2019), One in four people will have a stroke in their lifetime. Prevention is the best medicine. On average, 90% of strokes are preventable by addressing risk factors like hypertension, diet, smoking, and exercise (WSO, 2019). However, according to the Centers for Disease Control and Prevention (CDC, n.d.), stroke is the leading cause of death for Americans. In the U.S., someone has a stroke every 40 seconds, and someone dies of a stroke every four minutes (CDC, n.d.). Unfortunately, the country's highest death rates from stroke are in the southeastern part of the county, where the trauma center is located (CDC, n.d.). </span></p>
                        <p><span data-preserver-spaces="true">Although addressing risk factors is the key to prevention, our patient population has historically not managed these health risks. Other diseases also put patients at a higher risk for stroke. The American Heart Association (AHA, n.d.) states diseases such as obesity, hypertension, hyperlipidemia, carotid artery disease, and A-Fib are contributing risk factors for stroke. Unfortunately, the adult obesity rate in North Carolina is above 35%, and this is just one contributing factor to stroke (North Carolina State Nutrition, Physical Activity, and Obesity Profile, n.d.). Nearly a quarter of ischemic stroke patients have a history of A-Fib (Perera et al., 2018). Obesity, A-Fib, and other comorbidities in our patient population can contribute to an in-hospital stroke.</span></p>
                        <h3><span data-preserver-spaces="true">NURS FPX 6021 Assessment 2 Change Strategy and Implementation</span></h3>
                        <p><span data-preserver-spaces="true">One in five strokes occur while a patient is in the hospital for other reasons (Yang et al., 2019). When a patient has a stroke as an inpatient, they have worse outcomes and more severe strokes than community-onset strokes (Green et al., 2021). In-hospital stroke patients have more significant delays in care than out-of-hospital stroke patients, resulting in increased length of stay, disability, and mortality (Kassardjian et al., 2017). </span></p>
                        <p><span data-preserver-spaces="true">In-hospital stroke patients require the same degree of care and prompt attention as those admitted to a specialized care unit (Green et al., 2021). Due to bed shortages, providing the same degree of care as a specialized care unit will not be possible. However, when appropriately trained, non-stroke-specific nurses will be capable of recognizing stroke symptoms, the proper protocols to follow, and what complications to be aware of.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Existing Health Policy&nbsp;</span></strong></h2>
                        <p><span data-preserver-spaces="true">The WSO and the AHA recommend patients be treated in a specialized inpatient stroke unit cared for by an interprofessional stroke team (Green et al., 2021). However, inpatient strokes happen in non-stroke-specific stroke units with staff that do not specialize in stroke care. Therefore, there is a need to train all clinical staff to recognize stroke symptoms in their patients. In their meta-analysis, Langhorne and Ramachandra (2020) assessed whether organized inpatient stroke care benefits were apparent across various patient groups. Their meta-analysis showed that organized stroke care did provide better patient outcomes independent of age, sex, stroke type, and duration of follow-up (Langhorne &amp; Ramachandra, 2020). The fact that an organized stroke care program produced better patient outcomes regardless of the patient population is relevant for the broad patient population at the trauma center.</span></p>
                        <p><span data-preserver-spaces="true">A 500-bed teaching hospital in Canada developed an inpatient code stroke algorithm and an educational intervention to address the time from last seen normal (LSN) to initial assessment (primary outcome) to time to brain imagining (secondary outcome) (Kassardjian et al., 2017). Although the hospital had a general neurology unit, they did not have a specialized stroke unit (Kassardjian et al., 2017). Before implementation, the hospital did not have a protocol for managing in-hospital strokes (Kassardjian et al., 2017). </span></p>
                        <p><span data-preserver-spaces="true">After the implantation of the new algorithm and educational intervention, there were consistent reductions in all-time outcomes, with the average time from LSN to initial assessment going from 600 minutes to 160 minutes and time to brain imaging falling from 925 minutes to 350 minutes (Kassardjian et al., 2017). These significant average time to treatment reductions clearly show how effective implementing a stroke management protocol can be.</span></p>
                        <ul>
                            <li><span data-preserver-spaces="true">In 2017, a 21-hospital medical system implemented a new inpatient stroke protocol across California (Droegemueller et al., 2018). The methodology comprised specialized nurse training and replaced a two-step activation process with a one-step procedure for nurses to call a code stroke using the same criteria used by EMS and ED triage nurses. (Droegemueller et al., 2018). </span></li>
                            <li><span data-preserver-spaces="true">Emphasis was placed on accurate documentation, and a note template was provided (Droegemueller et al., 2018). In the six months before implementing the new inpatient stroke protocol, there were five code strokes called, with one being an actual stroke, and in the six months after implementation, there were 46 code strokes called, with 15 of those being confirmed strokes (Droegemueller et al., 2018). The program's achievements were systemwide organization.</span></li>
                            <li><span data-preserver-spaces="true">Process simplification.</span></li>
                            <li><span data-preserver-spaces="true">We are matching the ED procedure.</span></li>
                            <li><span data-preserver-spaces="true">The presence of a stroke response team.</span></li>
                        </ul>
                        <p><span data-preserver-spaces="true">The increased number of code stroke calls is a goal the trauma center would strive for. A difference between this example and this needs assessment is that they did not have a stroke team or a dedicated stroke unit before implementation, making it slightly less relevant to the trauma center, which has a specialized stroke unit and a code stroke team. However, simplifying the process of calling a code and the system's comprehensive organization are valuable concepts to consider.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Conclusion</span></strong></h2>
                        <p><span data-preserver-spaces="true">The analysis of current evidence through this literature review establishes a need for a formalized stroke education program for non-stroke specialized nurses at the trauma center. The stroke education program is appropriate for the target population and setting. Several sources illustrate existing health policies that could influence the development of the stroke education program. The importance and validity of the proposed project have been brought forth.&nbsp;</span></p>
                        <h2><strong><span data-preserver-spaces="true">References</span></strong></h2>
                        <p><span data-preserver-spaces="true">American Heart Association (AHA). (n.d.). Stroke risk factors.</span></p>
                        <p><a href="https://www.stroke.org/en/aboutstroke/stroke-risk-factors" target="_blank"><span data-preserver-spaces="true">https://www.stroke.org/en/aboutstroke/stroke-risk-factors</span></a></p>
                        <p><span data-preserver-spaces="true">Centers for Disease Control and Prevention (CDC). (n.d.). Stroke facts.</span></p>
                        <p><a href="https://www.cdc.gov/stroke/facts.htm" target="_blank"><span data-preserver-spaces="true">https://www.cdc.gov/stroke/facts.htm</span></a></p>
                        <p><span data-preserver-spaces="true">Droegemueller, C. J., Wagner, R. L., Kashyap, B., Clayton, M. W., Fennig, M. W., Hanson, L. R., &amp; Hussein, H. M. (2018). Abstract TP370: Performance of a new "code stroke" process in hospitalized patients in a comprehensive stroke center in Minnesota.&nbsp;</span><em><span data-preserver-spaces="true">Stroke, 49</span></em><span data-preserver-spaces="true">(Suppl_1).</span></p>
                        <p><a href="https://doi.org/10.1161/str.49.suppl_1.tp370" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1161/str.49.suppl_1.tp370</span></a></p>
                        <p><span data-preserver-spaces="true">Green, T. L., McNair, N. D., Hinkle, J. L., Middleton, S., Miller, E. T., Perrin, S., Power, M., Southerland, A. M., &amp; Summers, D. V. (2021). Care of the patient with acute ischemic stroke (posthyperacute and prehospital discharge): Update to 2009 comprehensive nursing care scientific statement: A scientific statement from the American Heart Association.&nbsp;</span><em><span data-preserver-spaces="true">Stroke, 52</span></em><span data-preserver-spaces="true">(5).</span></p>
                        <p><a href="https://doi.org/10.1161/str.0000000000000357" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1161/str.0000000000000357</span></a></p>
                        <p><span data-preserver-spaces="true">H. Buck, B., Akhtar, N., Alrohimi, A., Khan, K., &amp; Shuaib, A. (2021). Stroke mimics Incidence, etiology, clinical features, and treatment.&nbsp;</span><em><span data-preserver-spaces="true">Annals of Medicine, 53</span></em><span data-preserver-spaces="true">(1), 420&ndash;436.</span></p>
                        <p><a href="https://doi.org/10.1080/07853890.2021.1890205" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1080/07853890.2021.1890205</span></a></p>
                        <p><span data-preserver-spaces="true">Kassardjian, C. D., Willems, J. D., Skrabka, K., Nisenbaum, R., Barnaby, J., Kostyrko, P., Selchen, D., &amp; Saposnik, G. (2017). Inpatient code stroke.&nbsp;</span><em><span data-preserver-spaces="true">Stroke, 48</span></em><span data-preserver-spaces="true">(8), 2176&ndash;2183.</span></p>
                        <p><a href="https://doi.org/10.1161/strokeaha.117.017622" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1161/strokeaha.117.017622</span></a></p>
                        <p><span data-preserver-spaces="true">Langhorne, P., &amp; Ramachandra, S. (2020). Organized inpatient (stroke unit) care for stroke: Network meta-analysis&mdash;Cochrane</span><em><span data-preserver-spaces="true"> Database of Systematic Reviews.</span></em></p>
                        <p><a href="https://doi.org/10.1002/14651858.cd000197.pub4" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1002/14651858.cd000197.pub4</span></a></p>
                        <p><span data-preserver-spaces="true">Mahoney, K., &amp; Simmonds, W. (2020). Using a health improvement methodology to standardize leg ulcer management.&nbsp;</span><em><span data-preserver-spaces="true">British Journal of Community Nursing, 25</span></em><span data-preserver-spaces="true">(Sup9), S20&ndash;S25.</span></p>
                        <p><a href="https://doi.org/10.12968/bjcn.2020.25.sup9.s20" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.12968/bjcn.2020.25.sup9.s20</span></a></p>
                        <p><span data-preserver-spaces="true">North Carolina state nutrition, physical activity, and obesity profile. (n.d.). Centers for Disease Control and Prevention.</span></p>
                        <p><a href="https://www.cdc.gov/nccdphp/dnpao/state-localprograms/profiles/pdfs/north-carolina-state-profile.pdf" target="_blank"><span data-preserver-spaces="true">https://www.cdc.gov/nccdphp/dnpao/state-localprograms/profiles/pdfs/north-carolina-state-profile.pdf</span></a></p>
                        <p><span data-preserver-spaces="true">North Carolina state nutrition, physical activity, and obesity profile. (2016). Centers for Disease Control and Prevention.</span></p>
                        <p><a href="https://www.cdc.gov/nccdphp/dnpao/state-localprograms/profiles/pdfs/north-carolina-state-profile.pdf" target="_blank"><span data-preserver-spaces="true">https://www.cdc.gov/nccdphp/dnpao/state-localprograms/profiles/pdfs/north-carolina-state-profile.pdf</span></a></p>
                        <p><span data-preserver-spaces="true">Perera, K. S., Sharma, M., Connolly, S. J., Wang, J., Gold, M. R., Hohnloser, S. H., Lau, C., Van Gelder, I. C., Morillo, C., Capucci, A., Israel, C. W., Botto, G., &amp; Healey, J. S. (2018). Stroke type and severity in patients with subclinical atrial fibrillation: An analysis from the asymptomatic atrial fibrillation and stroke evaluation in pacemaker patients and the atrial fibrillation reduction atrial pacing trial (ASSERT).&nbsp;</span><em><span data-preserver-spaces="true">American Heart Journal, 201</span></em><span data-preserver-spaces="true">, 160&ndash;163.</span></p>
                        <p><a href="https://doi.org/10.1016/j.ahj.2018.03.027" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1016/j.ahj.2018.03.027</span></a></p>
                        <p><span data-preserver-spaces="true">Tichnor-Wagner, A., Wachen, J., Cannata, M., &amp; Cohen-Vogel, L. (2017). </span><strong><span data-preserver-spaces="true">Continuous improvement in the public school context:</span></strong><span data-preserver-spaces="true"> Understanding how educators respond to plan&ndash;do&ndash;study&ndash;act cycles.&nbsp;</span><em><span data-preserver-spaces="true">Journal of Educational Change, 18</span></em><span data-preserver-spaces="true">(4), 465&ndash;494.</span></p>
                        <p><a href="https://doi.org/10.1007/s10833-017-9301-4" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1007/s10833-017-9301-4</span></a></p>
                        <p><span data-preserver-spaces="true">World Stroke Organization (WSO). (2019, November 18). Stroke Riskometer.</span></p>
                        <p><a href="https://www.world-stroke.org/publications-and-resources/resources/stroke-riskometer" target="_blank"><span data-preserver-spaces="true">https://www.world-stroke.org/publications-and-resources/resources/stroke-riskometer</span></a></p>
                        <p><span data-preserver-spaces="true">Yang, S., Franco, T., Wallace, N., Williams, B., &amp; Blackmore, C. (2019). Effectiveness of an interdisciplinary, nurse-driven in-hospital code stroke protocol on inpatient ischemic stroke recognition and management.&nbsp;</span><em><span data-preserver-spaces="true">Journal of Stroke and Cerebrovascular Diseases, 28</span></em><span data-preserver-spaces="true">(12), 104398.</span></p>
                        <p><a href="https://doi.org/10.1016/j.jstrokecerebrovasdis.2019.104398" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1016/j.jstrokecerebrovasdis.2019.104398</span></a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx6030assessment2
