import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Socfpx2000assessment3s = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>SOC FPX 2000 Assessment 3 | Social Activism Analysis</title>
                <meta name='description'
                    content='Explore causes, consequences, and solutions to racial discrimination in the U.S. criminal justice system. Learn how activism drives change and equity.' />
                <meta name='keywords' content='SOC FPX 2000 Assessment 3 Social Activism and Data Analysis' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>SOC FPX 2000 Assessment 3 < br /><span>Social Activism and Data Analysis</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Socfpx2000assessment3.webp" alt="SOC FPX 2000 Assessment 3 Social Activism and Data Analysis" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>SOC FPX 2000 Assessment 3 Social Activism and Data Analysis.</em></strong></li>
                        </ul>
                        <h2><strong>Introduction</strong></h2>
                        <p>African Americans face significant social and economic challenges, including poverty and lower high school graduation rates, when compared to their White counterparts (Patten &amp; Krogstad, 2015). However, these issues pale compared to the institutional inequality in the U.S. criminal justice system. African Americans are incarcerated at rates five times higher than White Americans, a stark indicator of systemic discrimination (Nellis, 2016).</p>
                        <p>This systemic bias has prompted activists and researchers to highlight these disparities to foster public awareness and action. Through an exploration of incarceration and policing practices, this analysis reveals the disparities between African Americans and White Americans in the criminal justice system. The goal is to understand the causes, consequences, and potential solutions to institutional racial discrimination while addressing <a href="https://www.writinkservices.com/soc-fpx-2000-assessment-3-social-activism-and-data-analysis/">SOC FPX 2000 Assessment 3</a>&nbsp;objectives.</p>
                        <h2><strong>Causes of Institutional Racial Discrimination</strong></h2>
                        <h3><strong>Historical Racism and Bias</strong></h3>
                        <p>The roots of racial discrimination in the U.S. criminal justice system stem from historical inequalities between African Americans and Whites. These disparities are perpetuated by stereotypes that label young Black men as dangerous (Tonry, 2010). This bias often influences law enforcement and judicial officials' decisions, resulting in systemic marginalization.</p>
                        <h3><strong>Racial Prejudices in Crime Policies</strong></h3>
                        <p>Negative racial stereotypes also influence policies designed to combat crime. Conservative perspectives often link African Americans with criminal activity, advocating for harsh punishments such as the death penalty (Tonry, 2010). In contrast, African Americans generally exhibit lower levels of support for severe penalties, reflecting their mistrust of the fairness of the criminal justice system.</p>
                        <h3><strong>Economic Inequality and Access to Justice</strong></h3>
                        <p>Economic disparities create a two-tiered justice system:</p>
                        <ol>
                            <li>Wealthier individuals access robust legal protections and resources.</li>
                            <li>Low-income individuals, particularly African Americans, face limited legal support and systemic neglect (The Sentencing Project, 2018).</li>
                        </ol>
                        <p>This division ensures that minority groups, especially African Americans, are disproportionately targeted and underserved by the justice system.</p>
                        <h2>Consequences of Institutional Discrimination</h2>
                        <h3><strong>Overrepresentation in Incarceration</strong></h3>
                        <p>By the end of 2015, over 2.2 million individuals were incarcerated in the U.S., with African Americans disproportionately represented. Black individuals are 5.9 times more likely to be incarcerated than Whites, while Hispanics are 3.1 times more likely (The Sentencing Project, 2018).</p>
                        <ul>
                            <li>African Americans make up 27% of all arrests despite comprising only 13% of the U.S. population.</li>
                            <li>Black youth, representing 15% of the population, accounted for 35% of juvenile arrests in 2016.</li>
                        </ul>
                        <p>These disparities are often attributed to concentrated poverty in urban areas, making African Americans more visible to law enforcement.</p>
                        <h3><strong>Targeting in Drug Enforcement</strong></h3>
                        <p>Drug-related offenses further highlight racial discrimination:</p>
                        <ul>
                            <li>African Americans were arrested at three times the rate of Whites for marijuana possession despite similar usage rates (Edwards, Bunting &amp; Garcia, 2013).</li>
                            <li>Black drivers are disproportionately stopped, searched, and arrested compared to their White counterparts (U.S. Bureau of Justice Statistics, 2013).</li>
                        </ul>
                        <p>These disparities reveal systemic bias in both policy enforcement and routine policing practices.</p>
                        <h2><strong>Proposed Policy Solutions</strong></h2>
                        <h3><strong>Alternative Rehabilitation Measures</strong></h3>
                        <p>For minor offenses, such as drug possession or prostitution, alternatives to incarceration should be prioritized. Sending offenders to rehabilitation and social service programs would be more effective in reducing recidivism and addressing the root causes of crime.</p>
                        <h3><strong>Guaranteed Legal Representation</strong></h3>
                        <p>To address inequities in legal representation, policies should ensure all individuals, regardless of income or race, have access to competent legal advocates. These measures would safeguard rights such as:</p>
                        <ul>
                            <li>Access to bail services.</li>
                            <li>Proper examination of evidence.</li>
                            <li>Fair trial processes.</li>
                        </ul>
                        <p>Such policies would help level the playing field and reduce systemic disparities.</p>
                        <h2><strong>Conclusion</strong></h2>
                        <p>Institutional racial discrimination within the criminal justice system disproportionately affects African Americans, leading to higher rates of arrest and incarceration. This issue is rooted in historical biases, economic inequality, and systemic inefficiencies that prioritize punishment over rehabilitation. SOC FPX 2000 Assessment 3 underscores the need for actionable solutions, including alternative sentencing measures and equitable legal representation.</p>
                        <p>By addressing these disparities, the U.S. can move closer to a justice system that upholds fairness and equality for all. Empowering communities and fostering informed activism are essential steps toward systemic change.</p>
                        <h2><strong>References</strong></h2>
                        <p>Tonry, M. (2010). The social, psychological, and political causes of racial disparities in the American criminal justice system. <em>Crime &amp; Justice, 502</em>, 273&ndash;312.</p>
                        <p>FBI Uniform Crime Reporting Program. (2015). Arrests by race and ethnicity, 2015. <em>Crime in the United States 2015</em>. Table 43A. Retrieved from</p>
                        <p><a href="https://bit.ly/2Prh0jF">https://bit.ly/2Prh0jF</a></p>
                        <p>Edwards, E., Bunting, W., &amp; Garcia, L. (2013). <em>The war on marijuana in Black and White</em>. New York, NY: American Civil Liberties Union.</p>
                        <p>Epp, C. R., Maynard-Moody, S., &amp; Haider-Markel, D. P. (2014). <em>Pulled over: How police stops define race and citizenship</em>. Chicago, IL: The University of Chicago Press.</p>
                        <p>Kahn, A., &amp; Kirk, C. (2015). What it&rsquo;s like to be Black in the criminal justice system. <em>Slate</em>. Retrieved from</p>
                        <p><a href="https://bit.ly/2PKfuba">https://bit.ly/2PKfuba</a></p>
                        <p>U.S. Bureau of Justice Statistics. (2013). <em>Police behavior during traffic and street stops, 2011</em>. BJS Special Report, NCJ 242937. Retrieved from</p>
                        <p><a href="https://www.bjs.gov/content/pub/pdf/pbtss11.pdf">https://www.bjs.gov/content/pub/pdf/pbtss11.pdf</a></p>
                        <p>The Sentencing Project. (2018, April 19). <em>Report to the United Nations on racial disparities in the U.S. criminal justice system</em>. The Sentencing Project. Retrieved from</p>
                        <p><a href="https://www.sentencingproject.org/publications/un-report-on-racial-disparities/">https://www.sentencingproject.org/publications/un-report-on-racial-disparities/</a></p>                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Socfpx2000assessment3s
