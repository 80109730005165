import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";
import { Helmet } from 'react-helmet';

const Capellauniversityflexpathnursing = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Research Writers",
            Desc: "Get help from skilled capella university flexpath nursing research writers. They are always ready for help.",
        },
        {
            icon: "fa-solid fa-school icon",
            title: "Class Help",
            Desc: "Our tutors offer capella university flexpath nursing class-taking services for capella university flexpath nursing!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Take help from capella university flexpath nursing professionals in their capella university flexpath nursing courses.",
        },
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Tutoring Help",
            Desc: "Take help from our tutors, who are capella university flexpath nursing Curriculum professionals. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a capella university flexpath nursing intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with your capella university flexpath nursing Curriculum and coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to capella university flexpath nursing students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from capella university flexpath nursing nursing experts.",
        },
    ]
    return (
        <>
            <Helmet>
                <title>Capella University Flexpath Nursing | Your Path to Success</title>
                <meta name='description'
                    content='Get expert help from Capella University FlexPath Nursing Program with one-on-one tuition, self-paced study, and complete course support.' />
                <meta name='keywords' content='Capella University Flexpath Nursing' />
            </Helmet>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
    <div className='sherotop-left'>
    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
    </div>
    <div className='cal-box'><Calculator/></div> 
</div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>Capella University Flexpath Nursing Program Assistance </span></h1>
                            <p className='ocs2-topText'>Are you seeking individualized assistance while pursuing your nursing degree? Online Course Services is here to help you earn your BSN (Bachelor of Science in Nursing) or advance your education with a graduate nursing degree using Capella University's innovative FlexPath format.</p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Completely original stuff</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Fast and hassle-free delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions to ensure excellence</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality guaranteed</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Professionals</h2>
                            <p>Take help from professional tutors, mentors, and writers to excel in your capella university flexpath nursing coursework.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Nursing Papers</h2>
                            <p>Get excellent and well-researched capella university flexpath nursing papers!</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET capella university flexpath nursing ASSESSMENT SOLUTIONS!</h2>
                <p>We provide excellent capella university flexpath nursing assessment solutions for students in their capella university flexpath nursing Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Educational Services</span>
                    <h2>Capella FlexPath Nursing Program Assisting Services</h2>
                    <p>
                        Stuck on an assignment? Need help navigating My Capella University? No problem, we’re all about delivering high-quality, high-scoring support that ensures you stay ahead of the game.<br />
                        Capella Flexpath Nursing Program is aimed at working, practicing nurses who desire to augment their knowledge and skills to cater to the dynamic market. The FlexPath model provides students with a chance to manage their time independently so they can freely attend to other responsibilities. Self-paced learning is more advantageous for learners with experience in nursing; thus, they can cover more lessons within a shorter time than others.<br />
                        Our supportive services help students tackle challenges in the Capella University FlexPath Nursing program, offering career guidance, assessment insights, and on-call tutoring per their needs.<br />
                        With us, you’ll have the confidence and resources to excel in your Capella University FlexPath Nursing program. Let’s take your journey to the next level together.
                    </p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>Professional Assistance for Career</strong></Tab>
                        <Tab><strong>Expert Tutors with FlexPath Nursing Program Assistance</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>We understand that a nursing degree is about more than simply learning; it's about boosting your career. That is why we provide career guidance, resume clinics, and practice interviews specialized to nursing grads. With these resources, you'll be ready to take on the obstacles of joining the workforce and confidently launching your career.<br />
                            <h5>Capella FlexPath Nursing Program Curriculum Support</h5>
                            The curriculum is not easy to maneuver. The nursing course selection and an academic plan within the Capella FlexPath Nursing Program are provided with a detailed description by our team. This way, we assist you in mastering the structure that the program offers by means of helping you gain a smooth understanding of all the requirements to be met by you with the help of choosing an appropriate strategy for studying to correspond to your career interests. <br />
                            <h5>Personalized Academic Support</h5>
                            We offer personalized academic support with one-on-one sessions to create schedules and strategies tailored just for you, keeping you organized and focused on your goals.<br />

                        </p>
                    </TabPanel>
                    <TabPanel>
                        <p>
                            We offer highly qualified tutors who are knowledgeable in nursing instruction as well as in the Capella FlexPath exams. They provide extra support to help you understand nursing concepts, master assessments, and complete your academic tasks.<br />
                            <h5>Flexible Learning Solutions </h5>
                            Being an online platform, the FlexPath model enables learning to take place at the convenience of the learner. To help you achieve this flexibility, we offer you ways of setting reasonable time bounds and tackling all your work.
                            <h5>Enhance Your Capella FlexPath Nursing Program Help Experience</h5>
                            In addition to enhancing your understanding of the materials, we also provide additional resources like supplementary materials, discussion boards, and support groups, offering nursing students a platform to share ideas and effective strategies..<br />
                            Get in touch with us right now to find out how our Capella customer service can help you achieve your learning objectives with unparalleled knowledge and commitment.

                        </p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h3>Why Choose Our Assistance?</h3>
                    <p><h5>Tailored Learning Experience</h5>
                        Capabilities within the Capella FlexPath Nursing Program are specialized for the student’s level of need to guarantee that you are provided the appropriate assistance throughout your educational process. <br />
                        <h5>Creating an Innovative Comprehensive Programme for Career Development</h5>
                        In this regard, we aim to prepare you with the relevant skills needed to do well in the nursing career by providing robust career services that will help you secure a better job upon completion.<br />
                        <h5>Access to Experienced Faculty</h5>
                        Combined with the strength of faculty members’ experience, we guarantee students are receiving both sound knowledge and practice from instructors who can help them improve the nursing field.<br />
                        <h5>Flexible Scheduling Options</h5>
                        Being a flexible learning format, the FlexPath presents an opportunity to learn while working and attending to other responsibilities in equal measure with the quality of learning not being in any way compromised.<br />
                        <h5>Proven Success Rates</h5>
                        Many learners who turn to our help stick with their courses longer and experience more satisfaction in their studies, which proves our dedication to your success.<br />

                    </p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}> What is the Capella FlexPath Nursing Program?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Capella FlexPath, Nursing Program is a flexible online learning modality in that students can move through a concept-based curriculum at a pace to caters to working individuals.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>In what ways does Capella customer service help the students?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Regarding support services, Capella student care engulfs customers’ academic, technical, and career support so that the students within the organization get to benefit from the services by getting necessary support throughout their studies.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>That leads to the other question – what are Capella FlexPath assessments like?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Unlike traditional tests, Capella FlexPath assessments emphasize competencies to solve practical tasks instead of taking exams, which would help learners reveal how strong they are at applying what they know.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </Container>
        </>

    )
}

export default Capellauniversityflexpathnursing
