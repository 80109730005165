import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";
import { Helmet } from 'react-helmet';

const Capellaflexpathrntobsn = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Research Writers",
            Desc: "Get help from skilled capella flexpath rn to bsn research writers. They are always ready for help.",
        },
        {
            icon: "fa-solid fa-school icon",
            title: "Class Help",
            Desc: "Our tutors offer capella flexpath rn to bsn class-taking services for capella flexpath rn to bsn!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Take help from Ccapella flexpath rn to bsn professionals in their capella flexpath rn to bsn courses.",
        },
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Tutoring Help",
            Desc: "Take help from our tutors, who are capella flexpath rn to bsn Curriculum professionals. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a capella flexpath rn to bsn intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with your capella flexpath rn to bsn Curriculum and coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to capella flexpath rn to bsn students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from capella flexpath rn to bsn nursing experts.",
        },
    ]
    return (
        <>
            <Helmet>
                <title>Capella FlexPath RN to BSN | Flexible Online Program</title>
                <meta name='description'
                    content='Are you struggling with Capella RN to BSN FlexPath? Get expert help to manage time, simplify studies, and excel in your program effortlessly!' />
                <meta name='keywords' content='capella flexpath rn to bsn' />
            </Helmet>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
    <div className='sherotop-left'>
    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
    </div>
    <div className='cal-box'><Calculator/></div> 
</div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>Capella FlexPath RN to BSN Program Assistance</span></h1>
                            <p className='ocs2-topText'>Get professional assistance to excel in your Capella RN to BSN FlexPath coursework.</p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Completely original stuff</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Fast and hassle-free delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions to ensure excellence</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality guaranteed</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Professionals</h2>
                            <p>Take help from professional tutors, mentors, and writers to excel in your capella flexpath rn to bsn coursework.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Nursing Papers</h2>
                            <p>Get excellent and well-researched capella flexpath rn to bsn nursing papers!</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET capella flexpath rn to bsn ASSESSMENT SOLUTIONS!</h2>
                <p>We provide excellent capella flexpath rn to bsn assessment solutions for students in their capella flexpath rn to bsn Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Educational Services</span>
                    <h2>Expert Assistance for Capella FlexPath Courses</h2>
                    <p>Our customized services for Capella University RN to BSN FlexPath students can help you reach your full potential. We have a stellar reputation for providing excellent support to help you succeed academically.
                        <br />
                        Our staff of experienced academic writers, tutors, and mentors is committed to assisting you with Capella RN to BSN papers, nursing examinations, and coursework matched with the capella flexpath rn to bsn Curriculum. We guarantee that you receive support tailored toward success regardless of whether you need assistance with your online classes, your projects, or your tests.
                        When you choose our service, you get a variety of excellent, high score services that will ease study pressure while not burning a hole through your pocket. The support we provide is valuable and affordable to help you get the highest value from the Capella RN to BSN FlexPath cost. Experience excellent outcomes with our complete writing and tutoring services designed for Capella University RN to BSN FlexPath students.
                    </p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>Professional Assistance for Your Nursing Career</strong></Tab>
                        <Tab><strong>Cost-effective Options</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>Are you tackling the tough Capella FlexPath RN to BSN program? Online Course Services’ aim is to assure success! In providing academic help, our experts make sure that you meet the requirements of the rigorous Capella RN to BSN FlexPath program at every level of your learning process. We can help you complete your learning objectives without worrying about stress.
                            <br />
                            <h5>Comprehensive Capella RN to BSN Papers Assistance</h5>
                            It can be difficult to generate outstanding papers especially when writing Capella RN to BSN papers; however, help is on hand! We can help with any research, writing an academic paper or organizing assignment. All our services provided are tailored to suit RN to BSN course requirements to ensure that you submit high-quality, original, and well-written articles.
                            <br />
                            <h5>Customized Assistance for Capella University RN to BSN FlexPath</h5>
                            Each student has different requirements regarding their work, and we at Online Course Services understand it fully well. For this reason, we provide Academic assistance to all Capella University RN to BSN FlexPath students. Academic skills are also covered by our tutors in regards to course work, organizing themselves, and getting assistance and personalized tutorial. Get successful in the FlexPath program and grasp the material with our assistance.

                        </p>
                    </TabPanel>
                    <TabPanel>
                        <p>We believe every student deserves quality support at an affordable price. Our competitive rates for Capella RN to BSN FlexPath courses make academic help accessible. Don’t let cost hold you back—our flexible, budget-friendly options ensure you get the assistance you need.
                            <br />
                            <h5>Capella RN to BSN FlexPath Academic Coaching</h5>
                            Get expert academic coaching alongside tutoring for your Capella RN to BSN FlexPath. Our certified coaches will help enhance critical thinking skills, time management, and study skills. By leveraging on proper support, FlexPath program will be great and goal oriented.
                            <br />
                            <h5>Enhance Your Experience</h5>
                            The Capella RN to BSN FlexPath program can be challenging, but our support ensures your success. We respond to doubts within assignments; understanding of course content; grasping the material. Come get personal attention towards reaching your dream nursing career today.

                        </p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h3>Why Choose Our Assistance?</h3>
                    <p><h5>
                        Customized Learning Support </h5>
                        The tutoring services that are offered by us are right the way through convenient to meet your learning strategy and goals.
                        <h5>Flexible Scheduling</h5>
                        It is for this reason that we have made available support that is able to fit into everyone’s busy lifestyle.
                        <br />
                        <h5>Verified Results</h5>
                        With our guidance, our students have been able to complete the Capella course: RN to BSN FlexPath on the most part.
                        <br />
                        <h5>Professional Tutors</h5>
                        Our tutors are highly qualified, effective and familiar with the programs within Capella University and specifically the RN to BSN FlexPath.
                        <br />
                        <h5>Cost-effective Services</h5>
                        We provide competitive prices to make sure you can afford excellent academic assistance.
                        <br />
                    </p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}> What assistance can you provide with Capella RN to BSN papers?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    In order to assist you succeed in your Capella RN to BSN projects and papers, we offer professional writing, editing, and tutoring.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>How much does the Capella RN to BSN FlexPath cost?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    It is important to note that while Capella University RN to BSN FlexPath tuition rates do differ we ensure affordable fees to enhance your overall expense of achieving a BSN.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>What can I gain out of hiring your service as my tutor to improve my grades?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Our professional tutors will guide you through the curriculum, help with DO-It-Yourself and develop higher thinking and time management proficiencies.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Do you offer assistance with the Capella RN to BSN course?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes, we provide thorough exam preparation that includes study techniques, advice, and individualized tutoring.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </Container>
        </>

    )
}

export default Capellaflexpathrntobsn
