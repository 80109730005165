import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Mbafpx5012assessment1 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>MBA FPX 5012 Assessment 1 | Marketing Plan Guide</title>
                <meta name='description'
                    content='Discover actionable insights for crafting a winning marketing plan. Analyze strategies, target markets, and corporate goals for success.' />
                <meta name='keywords' content='MBA FPX 5012 Assessment 1 Marketing Plan' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>MBA FPX 5012 Assessment 1 < br /><span>Marketing Plan</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Mbafpx5012assessment1.webp" alt="MBA FPX 5012 Assessment 1 Marketing Plan" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong><span data-preserver-spaces="true">MBA FPX 5012 Assessment 1 Marketing Plan.</span></strong></em></li>
                        </ul>
                        <h2><strong><span data-preserver-spaces="true">Marketing Plan</span></strong></h2>
                        <h3><strong><span data-preserver-spaces="true">Leader Summary</span></strong></h3>
                        <p><span data-preserver-spaces="true">The proposed product is a frozen, pre-portioned raw canine food package offered through a monthly subscription service. These packages include raw meat, chewable bone fragments, organic fruits and vegetables, and essential supplements. As pet owners increasingly adopt "back-to-basics" diets for themselves, many are extending the same philosophy to their pets. Feeding pets raw food aligns with the growing desire to "spoil" them while prioritizing health and nutrition.</span></p>
                        <p><span data-preserver-spaces="true">The corporate goals for this product include:</span></p>
                        <ul>
                            <li><span data-preserver-spaces="true">Achieving a 0.5% increase in overall revenue, equating to $120 million.</span></li>
                            <li><span data-preserver-spaces="true">Expanding the existing customer base.</span></li>
                        </ul>
                        <p><span data-preserver-spaces="true">This MBA FPX 5012 Assessment 1 marketing plan evaluates the strategy to achieve these goals by catering to an underserved niche in the pet food market.</span></p>
                        <h3><strong><span data-preserver-spaces="true">Target Audience</span></strong></h3>
                        <p><span data-preserver-spaces="true">The primary audience comprises dog owners, as studies suggest that raw food diets are generally safe for dogs while posing risks for cats. This product will compete in the mainstream pet food market while introducing MSH brands into the premium nutritional pet food segment.</span></p>
                        <h3><strong><span data-preserver-spaces="true">Marketing Strategy</span></strong></h3>
                        <p><span data-preserver-spaces="true">The marketing strategy focuses on:</span></p>
                        <ul>
                            <li><span data-preserver-spaces="true">We use free social media platforms to share educational content, videos, tips, and tricks to drive brand awareness and engagement.</span></li>
                            <li><span data-preserver-spaces="true">We are partnering with influencers to promote the product and amplify visibility.</span></li>
                        </ul>
                        <h3><strong><span data-preserver-spaces="true">Subscription-Based Distribution Model</span></strong></h3>
                        <p><span data-preserver-spaces="true">A standout feature of this product is its subscription-based distribution model, offering buyers the convenience of pre-scheduled deliveries. Customers can choose from flexible package tiers, with discounts available for biannual or annual payments. This model emphasizes savings, convenience, and premium quality.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Business Context</span></strong></h2>
                        <h3><strong><span data-preserver-spaces="true">Product Overview</span></strong></h3>
                        <p><span data-preserver-spaces="true">The new product is a subscription-based, customizable frozen raw canine food package for all dog breeds and sizes. Each box includes vacuum-sealed portions of raw meats, vegetables, fruits, and vitamin supplements, with clear thawing instructions to minimize bacterial risks. Pet owners can serve the meals directly from the freezer for optimal safety and convenience.</span></p>
                        <p><span data-preserver-spaces="true">The details in this MBA FPX 5012 Assessment 1 marketing plan highlight how the product's unique qualities and distribution methods cater to the evolving demands of health-conscious pet owners.</span></p>
                        <h3><strong><span data-preserver-spaces="true">Market Trends</span></strong></h3>
                        <p><span data-preserver-spaces="true">The demand for raw canine diets has surged in recent years. While controversial, the benefits&mdash;such as improved coat health, dental hygiene, and vitality&mdash;have drawn significant interest from pet owners. Subscription services are also gaining traction as consumers value convenience and time savings.</span></p>
                        <p><span data-preserver-spaces="true">Unlike traditional pet food, this product offers a unique blend of all-natural ingredients and easy subscription management. Few competitors in the market provide a similar service, allowing MSH to capture a niche market rapidly.</span></p>
                        <h3><strong><span data-preserver-spaces="true">Addressing Challenges</span></strong></h3>
                        <ol>
                            <li><strong><span data-preserver-spaces="true">Safety Concerns:</span></strong><span data-preserver-spaces="true">&nbsp;Critics argue that raw diets may be harmful due to bacteria or bone choking hazards. To address this:</span>
                                <ul>
                                    <li><span data-preserver-spaces="true">Educational materials will highlight the health benefits.</span></li>
                                    <li><span data-preserver-spaces="true">High safety standards during processing and packaging will minimize risks.</span></li>
                                </ul>
                            </li>
                            <li><strong><span data-preserver-spaces="true">Operational Adjustments:</span></strong><span data-preserver-spaces="true">&nbsp;Producing raw food requires stringent hygiene and temperature controls. To ensure safety:</span>
                                <ul>
                                    <li><span data-preserver-spaces="true">Workers will follow strict sanitation, temperature maintenance, and bacterial testing protocols.</span></li>
                                </ul>
                            </li>
                        </ol>
                        <h3><strong><span data-preserver-spaces="true">Corporate Objectives</span></strong></h3>
                        <ul>
                            <li><strong><span data-preserver-spaces="true">Revenue Growth:</span></strong><span data-preserver-spaces="true">&nbsp;Increase revenue by $120 million, achieving an 8.5% market share.</span></li>
                            <li><strong><span data-preserver-spaces="true">Customer Expansion:</span></strong><span data-preserver-spaces="true">&nbsp;Attract pet owners who are currently preparing homemade raw food, offering them a convenient and safer alternative.</span></li>
                        </ul>
                        <h2><strong><span data-preserver-spaces="true">Situation Analysis</span></strong></h2>
                        <h3><strong><span data-preserver-spaces="true">Current Capabilities</span></strong></h3>
                        <p><span data-preserver-spaces="true">MSH's product portfolio includes dry, wet, and refrigerated pet foods and nutritional supplements. Our reputation for quality, affordability, and accessibility positions us as a trusted brand. The introduction of the new product will:</span></p>
                        <ul>
                            <li><span data-preserver-spaces="true">Complement the existing lineup with a comprehensive range of options.</span></li>
                            <li><span data-preserver-spaces="true">Encourage cross-purchasing other MSH products for mixed-diet pets, such as dry or wet foods.</span></li>
                            <li><span data-preserver-spaces="true">Reinforce MSH's commitment to pet health and nutrition.</span></li>
                        </ul>
                        <h3><strong><span data-preserver-spaces="true">Target Market</span></strong></h3>
                        <p><span data-preserver-spaces="true">The primary target market includes:</span></p>
                        <ul>
                            <li><span data-preserver-spaces="true">Dog owners are dissatisfied with current pet food options.</span></li>
                            <li><span data-preserver-spaces="true">Pet parents who prioritize their pets' health and nutrition.</span></li>
                        </ul>
                        <h3><strong><span data-preserver-spaces="true">Market Dynamics</span></strong></h3>
                        <p><span data-preserver-spaces="true">The global pet food market was valued at $83.02 billion in 2018, with an expected growth rate of 4.5% through 2025. In the U.S., the pet food market reached $24.6 billion in 2016, with dry food dominating due to convenience and shelf life. However, raw and nutritious food categories are rapidly gaining popularity among health-conscious pet owners.</span></p>
                        <h4><strong><span data-preserver-spaces="true">Raw Food Insights</span></strong></h4>
                        <ul>
                            <li><span data-preserver-spaces="true">Studies show that raw diets are generally safer for dogs than cats.</span></li>
                            <li><span data-preserver-spaces="true">Nutritional benefits include improved digestion, energy levels, and overall health.</span></li>
                        </ul>
                        <p><span data-preserver-spaces="true">This MBA FPX 5012 Assessment 1 demonstrates how the proposed product aligns with consumer demand and market gaps by addressing these trends.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Competitive Analysis</span></strong></h2>
                        <h3><strong><span data-preserver-spaces="true">Key Competitors</span></strong></h3>
                        <ol>
                            <li><strong><span data-preserver-spaces="true">Chewy</span></strong>
                                <ul>
                                    <li><span data-preserver-spaces="true">Offers subscription services but relies on third-party brands.</span></li>
                                    <li><span data-preserver-spaces="true">Features freeze-dried raw foods and specialized diets but lacks in-house production.</span></li>
                                </ul>
                            </li>
                            <li><strong><span data-preserver-spaces="true">Freshpet</span></strong>
                                <ul>
                                    <li><span data-preserver-spaces="true">Known for refrigerated pet foods, Freshpet uses steam-cooking methods and does not include bone fragments.</span></li>
                                    <li><span data-preserver-spaces="true">Though smaller in market share, Freshpet's focus on freshness appeals to the same health-conscious demographic.</span></li>
                                </ul>
                            </li>
                        </ol>
                        <h3><strong><span data-preserver-spaces="true">Opportunity Landscape</span></strong></h3>
                        <p><span data-preserver-spaces="true">The raw pet food market needs to be developed, with few competitors offering high-quality, subscription-based options. By combining innovation with rigorous safety standards, MSH can seize this opportunity to become a leading brand in the space.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Conclusion</span></strong></h2>
                        <p><span data-preserver-spaces="true">This <a href="https://www.studocu.com/en-us/document/capella-university/marketing-management/mbafpx-5012-biju-dolly-assessment-1-1/24987514">MBA FPX 5012 Assessment 1 marketing plan</a> comprehensively introduces MSH's frozen raw canine food subscription. By addressing safety concerns, leveraging the convenience of subscription models, and educating consumers on the benefits of raw diets, MSH is well-positioned to capture a growing segment of health-conscious pet owners. With this strategy, MSH aims to achieve its corporate objectives and establish itself as a premium pet food market leader.</span></p>
                        <h2><strong><span data-preserver-spaces="true">References</span></strong></h2>
                        <p><span data-preserver-spaces="true">AKC Staff. (2020).&nbsp;</span><strong><span data-preserver-spaces="true">Raw Dog Food Vs. Dry Kibble: Which Type Of Diet Is Best For Your Dog?</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from </span></p>
                        <p><a href="https://www.akc.org/expert-advice/nutrition/feed-my-dog-raw-food-or-dogkibble/">https<span data-preserver-spaces="true">://www.akc.org/expert-advice/nutrition/feed-my-dog-raw-food-or-dogkibble/</span></a></p>
                        <p><span data-preserver-spaces="true">B. (2020).&nbsp;</span><strong><span data-preserver-spaces="true">Top 10 Dog Names of 2020.</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from&nbsp;</span></p>
                        <p><a href="https://www.prudentpet.com/blog/top-10-dog-names/" target="_blank"><span data-preserver-spaces="true">https://www.prudentpet.com/blog/top-10-dog-names/</span></a></p>
                        <p><span data-preserver-spaces="true">Bedford, E. (2019).&nbsp;</span><strong><span data-preserver-spaces="true">Size of Pet Food Market US 2016-2022.</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from</span></p>
                        <p><a href="https://www.statista.com/statistics/755068/us-pet-food-market-size/#:~:text=The%20pet%20food%20market%20in,billion%20U.S.%20dollars%20by%202022" target="_blank"><span data-preserver-spaces="true">https://www.statista.com/statistics/755068/us-pet-food-market-size/#:~:text=The%20pet%20food%20market%20in,billion%20U.S.%20dollars%20by%202022</span></a></p>
                        <p><span data-preserver-spaces="true">Caldwell, D. (2017).&nbsp;</span><strong><span data-preserver-spaces="true">10 Pricing Strategies for Subscription Based Businesses.</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from </span></p>
                        <p><a href="https://www.moonclerk.com/subscription-pricing-strategies/">https<span data-preserver-spaces="true">://www.moonclerk.com/subscription-pricing-strategies/</span></a></p>
                        <p><span data-preserver-spaces="true">Campbell, P. (2020).&nbsp;</span><strong><span data-preserver-spaces="true">Subscription Marketing: Tactics for Marketing Your Subscription Service.</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from</span></p>
                        <p><a href="https://www.profitwell.com/blog/subscriptionmarketing" target="_blank"><span data-preserver-spaces="true">https://www.profitwell.com/blog/subscriptionmarketing</span></a></p>
                        <p><span data-preserver-spaces="true">Chewy. (n.d.). Retrieved from</span></p>
                        <p><a href="https://www.chewy.com/b/food-332" target="_blank"><span data-preserver-spaces="true">https://www.chewy.com/b/food-332</span></a></p>
                        <p><span data-preserver-spaces="true">Delventhal, S. (2020).&nbsp;</span><strong><span data-preserver-spaces="true">New Generation of Consumers Increase Demand for Natural Products.</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from&nbsp;</span></p>
                        <p><a href="https://www.investopedia.com/articles/investing/022217/study-shows-surge-demand-natural-products.asp" target="_blank"><span data-preserver-spaces="true">https://www.investopedia.com/articles/investing/022217/study-shows-surge-demand-natural-products.asp</span></a></p>
                        <p><span data-preserver-spaces="true">Diamond Pet Foods. (2017).&nbsp;</span><strong><span data-preserver-spaces="true">Pet Owners Want Their Pets to Eat Healthier Foods Than They Do.</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from&nbsp;</span></p>
                        <p><a href="https://www.globenewswire.com/news-release/2017/08/25/1100564/0/en/Pet-Owners-Want-Their-Pets-to-Eat-Healthier-Foods-Than-They-Do.html" target="_blank"><span data-preserver-spaces="true">https://www.globenewswire.com/news-release/2017/08/25/1100564/0/en/Pet-Owners-Want-Their-Pets-to-Eat-Healthier-Foods-Than-They-Do.html</span></a></p>
                        <p><span data-preserver-spaces="true">Dyck, A. (n.d.).&nbsp;</span><strong><span data-preserver-spaces="true">24 Bad Dog Food Ingredients to Avoid.</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from</span></p>
                        <p><a href="https://blog.homesalive.ca/bad-dog-food-ingredients-to-avoid" target="_blank"><span data-preserver-spaces="true">https://blog.homesalive.ca/bad-dog-food-ingredients-to-avoid</span></a></p>
                        <p><span data-preserver-spaces="true">EcommerceDB. (2019).&nbsp;</span><strong><span data-preserver-spaces="true">Chewy.com Revenue.</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from</span></p>
                        <p><a href="https://ecommercedb.com/en/store/chewy.com" target="_blank"><span data-preserver-spaces="true">https://ecommercedb.com/en/store/chewy.com</span></a></p>
                        <p><span data-preserver-spaces="true">Freshpet. (n.d.). Retrieved from&nbsp;</span><a href="https://freshpet.com/" target="_blank"><span data-preserver-spaces="true">https://freshpet.com/</span></a></p>
                        <p><span data-preserver-spaces="true">III. (n.d.).&nbsp;</span><strong><span data-preserver-spaces="true">Facts + Statistics: Pet Statistics.</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from&nbsp;</span></p>
                        <p><a href="https://www.iii.org/fact-statistic/facts-statistics-pet-statistics" target="_blank"><span data-preserver-spaces="true">https://www.iii.org/fact-statistic/facts-statistics-pet-statistics</span></a></p>
                        <p><span data-preserver-spaces="true">Kotler, P. T., &amp; Keller, K. L. (2016).&nbsp;</span><strong><span data-preserver-spaces="true">Framework for Marketing Management</span></strong><span data-preserver-spaces="true">&nbsp;(6th ed.). Boston, MA: Pearson.</span></p>
                        <p><span data-preserver-spaces="true">Ortiz-Ospina, E. (n.d.).&nbsp;</span><strong><span data-preserver-spaces="true">The Rise of Social Media.</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from&nbsp;</span></p>
                        <p><a href="https://ourworldindata.org/rise-of-social-media" target="_blank"><span data-preserver-spaces="true">https://ourworldindata.org/rise-of-social-media</span></a></p>
                        <p><span data-preserver-spaces="true">Pet Food Market Size, Share: Global Industry Report, 2019-2025. (2019). Retrieved from</span></p>
                        <p><a href="https://www.grandviewresearch.com/industry-analysis/pet-food-industry" target="_blank"><span data-preserver-spaces="true">https://www.grandviewresearch.com/industry-analysis/pet-food-industry</span></a></p>
                        <p><span data-preserver-spaces="true">Schlesinger, D., &amp; Joffe, D. (2011).&nbsp;</span><strong><span data-preserver-spaces="true">Raw Food Diets in Companion Animals: A Critical Review.</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from </span></p>
                        <p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3003575/">https<span data-preserver-spaces="true">://www.ncbi.nlm.nih.gov/pmc/articles/PMC3003575/</span></a></p>
                        <p><span data-preserver-spaces="true">Team Trefis. (2020).&nbsp;</span><strong><span data-preserver-spaces="true">Netflix Subscriber Growth 2x Expectations: Good News or Peak?</span></strong><span data-preserver-spaces="true">&nbsp;Retrieved from&nbsp;</span></p>
                        <p><a href="https://www.forbes.com/sites/greatspeculations/2020/04/28/netflix-subscriber-growth-2x-expectations-good-news-or-peak/#3a34734d3ea1" target="_blank"><span data-preserver-spaces="true">https://www.forbes.com/sites/greatspeculations/2020/04/28/netflix-subscriber-growth-2x-expectations-good-news-or-peak/#3a34734d3ea1</span></a></p>
                        <p><span data-preserver-spaces="true">Wheat, D. (2018). Retrieved from</span></p>
                        <p><a href="https://www.capitalpress.com/ag_sectors/orchards_nuts_vines/china-boosts-apple-exports-to-us/article_c5323c81-f49a-51f0-92d4-23656b785758.html" target="_blank"><span data-preserver-spaces="true">https://www.capitalpress.com/ag_sectors/orchards_nuts_vines/china-boosts-apple-exports-to-us/article_c5323c81-f49a-51f0-92d4-23656b785758.html</span></a></p>                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Mbafpx5012assessment1
