import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Hs230m5assignment = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>HS230 M5 Assignment | US Healthcare Evolution</title>
                <meta name='description'
                    content='Learn about HS230 M5 Assignment Evolution of the US Healthcare Delivery System with insights, timelines, and key reforms. Read more.' />
                <meta name='keywords' content='HS230 M5  Assignment Evolution of the US Healthcare Delivery System' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>HS230 M5  Assignment < br /><span>Evolution of the US Healthcare Delivery System</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Hs230m5assignment.webp" alt="HS230 M5  Assignment Evolution of the US Healthcare Delivery System" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em><span data-preserver-spaces="true">HS230 M5 Assignment Evolution of the US Healthcare Delivery System.</span></em></strong></li>
                        </ul>
                        <h2><strong><span data-preserver-spaces="true">Evolution of the US Healthcare Delivery System</span></strong></h2>
                        <p><span data-preserver-spaces="true">There was a point in the history of America when formal healthcare did not hold significant consequences. It was an internecine misery; members who were ailing would stay home, finding comfort within the bosom of the family, which did all that was possible by attempting to provide such care as needed. Today's fact sheet is all about how the perspective gets altered-moments of rupture if you will. Such instances came to the forefront when there was a scope for improvement, and leaders and stakeholders surfaced.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Timeline of Key Events</span></strong></h2>
                        <p><span data-preserver-spaces="true">It should also tell how the system was recreated, its foundations laid accurately, and historical avenues.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Early Informal Care and Lack of Formalized Training (Pre-19th Century)</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">Insights of this nature are further captured in the general history of the US healthcare delivery system. Accounts in the narration of events previously made portray the essential moments that point toward possibilities of change in its present condition.</span></p>
                        <p><span data-preserver-spaces="true">While structured education in this sphere was impossible, diversity was natural, and their role was flexible: basic repair procedures are usually mastered during performance. Their creativity and struggle entailed unbelievable losses in people's lives due to irrevocable diseases and traumas inflicted because of ignorance of medicine and intended measures.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Emergence of Hospitals and Rudimentary Medical Education (Late 18th to 19th Century)</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">It was in the 18th Century that it became regarded as an essential part of the professional health care service. As Hernandez et al. (2021) noted, by that time, a hospital began its transformation from no more than a soup kitchen and a shelter for needy people into a professionally recognized provider of healthcare services.</span></p>
                        <p><span data-preserver-spaces="true">This area of education was fundamental, but the establishment of organized medical training institutions does relate to professionalization. The period did, however, emphasize a change in health practices, policies, and standards.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Development of Sanitation and Public Health Initiatives (Late 19th to Early 20th Century)</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">The 19th and early 20th centuries created tremendous opportunities for progress because people's attention was now on how poor conditions fueled disease (Omeh et al., 2024). In addition, efforts to improve hygiene, alongside the setting up of various health departments, were fundamental in the fight to keep infectious diseases at bay and improve the general health of the population.</span></p>
                        <p><span data-preserver-spaces="true">This gave birth to the development of the principles, methods, and ideas on which preventive medicine stands; it is based on strategies for reducing diseases that are fundamental in promoting health nationally.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Introduction of Employer-Sponsored and Public Health Insurance (20th Century)</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">Century dred-year-old gare sCenturynderwent some significant change in the Twenty-First Century. OCenturyCenturies of the Century was the Centurion of funds and employer-baCenturylth insurance plans. One such Plan was used during the Second World War to find and retain workers when wage increases were not impossible.&nbsp;</span><span data-preserver-spaces="true">Jefferson et al. (2022).</span></p>
                        <p><span data-preserver-spaces="true">On the contrary, the introduction of Medicare and Medicaid in 1965 helped cover the insurance needs of older and low-income people, significantly impacting the provision and use of health care (Donohue et al., 2022).</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Implementation of Medicare and Medicaid (1965)</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">According to Magarinos et al. (2022), 1965 has gone into the history books as a defining moment in the annals of the United States of America regarding the provision of Medicare and Medicaid programs. Henceforth, the over 65-aged population and even low-income families were covered under the Reyes insurance program initiated by the government, of which the Medicare Program was a part.</span></p>
                        <p><span data-preserver-spaces="true">These programs probably reduced the financial barriers to utilizing health services and improved health in these underprivileged populations. This is quite a step toward meeting the goals of Universal Health Coverage in America.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Theoretical Basis for Development</span></strong></h2>
                        <ul>
                            <li><strong><span data-preserver-spaces="true">Factors Shaping Healthcare Evolution</span></strong></li>
                        </ul>
                        <p><span data-preserver-spaces="true">There have been developmental trends in the US healthcare delivery system over time due to the interaction of different social, economic, and political forces that have each played theoretical roles and directed the system over time.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Social Factors</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">Economic considerations play a focal role in shaping the US healthcare delivery system since these economic factors affect decisions concerning healthcare support, resource support, and delivery models. Adjusting healthcare services' cost, access, and quality is an enduring test, as healthcare expenditures account for substantial public spending (Montez et al., 2021).</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Economic Factors</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">Economic factors such as rising healthcare costs, mechanical advancements, and the prevalence of persistent diseases have fueled debates surrounding healthcare reform and the requirement for imaginative approaches to further foster effectiveness and affordability. The emergence of value-based care models and installment reforms seeks to adjust cash-related incentives to quality outcomes, influencing more basic accountability and sustainability inside the healthcare system.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Political Factors</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">The US provides the best and most inclusive patient care because the healthcare system offers much. It has various people and places, technologies, and funding sources, and its unique functions make it possible to provide healthcare services.</span></p>
                        <p><span data-preserver-spaces="true">US healthcare delivery tends to be rather political as well &ndash; the healthcare provision system was shaped by a political evolution, where the government played a role in healthcare strategies and regulations. This implies that the transaction between the actors in question &minus; the policymakers, the providers, the insurers, and the sponsorship networks &minus; is characterized by conflicting goals and ideas that determine decision-making about healthcare in general.</span></p>
                        <p><span data-preserver-spaces="true"><a href="https://www.youtube.com/watch?v=K6PMu-3o-EU">HS230 M5 Assignment Evolution of the US Healthcare Delivery&nbsp;</a><a href="https://www.youtube.com/watch?v=K6PMu-3o-EU" target="_blank">System&nbsp;</a>discusses&nbsp;the timing of significant legislative acts, such as the Social Security Act of 1935 and the passage of the Affordable Care Act (ACA) in 2010, among efforts to reform the US healthcare system. These aspects add to the political context of events and reflect the difficulty of reaching a policy consensus on healthcare. Warren &amp; Kavanagh, 2023.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Description of Resources</span></strong></h2>
                        <ul>
                            <li><strong><span data-preserver-spaces="true">Key Healthcare System Resources</span></strong></li>
                        </ul>
                        <p><span data-preserver-spaces="true">The US healthcare delivery system comprises vast resources for nationwide comprehensive and successful patient care. These resources encompass a diverse range of people, places, technologies, and funding sources, each playing an essential part in supporting the delivery of healthcare services.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">People</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">As per the present organizational setting in healthcare, practitioners, especially nurses, health auxiliary workers, doctors, administrative managers, and the entire workforce are the key components of the healthcare delivery mechanism (Reist et al., 2022). Such individuals are stress relievers in diagnosing, treating, and managing patients' well-being. Quality assurance and patient well-being career services are provided within different centers, including hospitals, clinics, essential care providers' practices, and unspecial practicing care practice facilities (Reist et al., 2022). These patients are sites of the healthcare sphere where they may receive preventive care, primary and acute care, and routine services.&nbsp;</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Technologies</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">As medicine evolves, better services through improved diagnostics, better implants, and advances in patient monitoring will emerge (Lee &amp; Yoon, 2021).&nbsp;</span><span data-preserver-spaces="true">Healthcare providers can now ensure proper and prompt action for patients anywhere they stay by using medical diagnostic modalities, EHRs, telemedicine, and other intelligent health devices.</span></p>
                        <ul>
                            <li>
                                <h3><strong><span data-preserver-spaces="true">Funding Sources</span></strong></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">The healthcare delivery system of the United States is built upon multiple funding bases that enable it to remain operational and attend to its patients. Such funding bases consist of private health insurance, Medicare, and Medicaid, which are government-funded, co-pay by patients, and voluntary donations (Reist et al., 2022). Each respective funding base has its place in providing healthcare services and even the participation of people with different economic levels in accessing medical care.</span></p>
                        <h2><strong><span data-preserver-spaces="true">Conclusion</span></strong></h2>
                        <p><span data-preserver-spaces="true">The US healthcare system is an ideal illustration of how trade, in its historical, theoretical, and practical aspects, may get entangled at different stages in its development over more or less prolonged periods.</span></p>
                        <p><span data-preserver-spaces="true">From its modest inception to its present form, the system has been subject to forces of change inspired by social, economic, and political dynamics. The ability to honestly examine the complexities of the healthcare system and engage current problems requires an understanding of the system's historical setting, theoretical underpinnings, and available resources. Explore our assignment <a href="https://onlinecourseservices.us/hs165-m5-assessment-1-advancing-health-equity">HS165 M5 Assessment 1</a>&nbsp;for more information about other courses.</span></p>
                        <h2><strong><span data-preserver-spaces="true">References</span></strong></h2>
                        <p><span data-preserver-spaces="true">Donohue, J. M., Cole, E. S., James, C. V., Jarlenski, M., Michener, J. D., &amp; Roberts, E. T. (2022). The US Medicaid program: Coverage, financing, reforms, and implications for health equity</span><em><span data-preserver-spaces="true">. Journal of the American Medical Association, 328(11),</span></em><span data-preserver-spaces="true">&nbsp;1085&ndash;1099.</span></p>
                        <p><span data-preserver-spaces="true">https://doi.org/10.1001/jama.2022.14791</span></p>
                        <p><span data-preserver-spaces="true">DrPH, R. L. G., MPH, K. G., PhD, &amp; CPH, T. D., MD, MPHTM. (2024</span><em><span data-preserver-spaces="true">). Introduction to public health: Promises and practices</span></em><span data-preserver-spaces="true">. In Google Books. Springer Publishing Company.</span></p>
                        <p><span data-preserver-spaces="true">https://books.google.com/books?hl=en&amp;lr=&amp;id=K17GEAAAQBAJ&amp;oi=fnd&amp;pg=PP1&amp;dq=The+late+19th+and+early+20th+centuries+saw+advancements+in+public+health+and+sanitation&amp;ots=H3X4bLe91a&amp;sig=BIg9nJ9ByG86NiEM79W6VKiMd9U</span></p>
                        <p><span data-preserver-spaces="true">Hernigou, P., Hernigou, J., &amp; Scarlat, M. (2021). The dark age of medieval surgery in France in the first part of the Middle Ages (500&ndash;1000): Royal touch, wound suckers, bizarre medieval surgery, monk surgeons, saint healers, but the foundation of the oldest worldwide still-operating hospital.&nbsp;</span><em><span data-preserver-spaces="true">International Orthopaedics</span></em><span data-preserver-spaces="true">.</span></p>
                        <p><span data-preserver-spaces="true">https://doi.org/10.1007/s00264-020-04914-1</span></p>
                        <p><span data-preserver-spaces="true">Jefferson, H., Gandara, J. L., Cohen, C. J., Gonz&aacute;lez, Y. M., Thorpe, R. U., &amp; Weaver, V. M. (2022). Beyond the ballot box: A conversation about democracy and policing in the United States.&nbsp;</span><em><span data-preserver-spaces="true">Annual Review of Political Science, 26(1).</span></em></p>
                        <p><span data-preserver-spaces="true">https://doi.org/10.1146/annurev-polisci-060722-103142</span></p>
                        <p><span data-preserver-spaces="true">Lee, D., &amp; Yoon, S. N. (2021). Application of artificial intelligence-based technologies in the healthcare industry: Opportunities and challenges.&nbsp;</span><em><span data-preserver-spaces="true">International Journal of Environmental Research and Public Health, 18(1),</span></em><span data-preserver-spaces="true">&nbsp;271.</span></p>
                        <p><span data-preserver-spaces="true">https://doi.org/10.3390/ijerph18010271</span></p>
                        <p><span data-preserver-spaces="true">Magarinos, J., Patel, T., Strunk, J., Naunheim, K., &amp; Erkmen, C. P. (2022). A history of health policy and health disparity.&nbsp;</span><em><span data-preserver-spaces="true">Thoracic Surgery Clinics, 32(1),</span></em><span data-preserver-spaces="true">&nbsp;1&ndash;11.</span></p>
                        <p><span data-preserver-spaces="true">https://doi.org/10.1016/j.thorsurg.2021.09.013</span></p>
                        <p><span data-preserver-spaces="true">Mallinson, D. J., &amp; Shafi, S. (2022). Smart home technology: Challenges and opportunities for collaborative governance and policy research.&nbsp;</span><em><span data-preserver-spaces="true">Review of Policy Research</span></em><span data-preserver-spaces="true">.</span></p>
                        <p><span data-preserver-spaces="true">https://doi.org/10.1111/ropr.12470</span></p>
                        <p><span data-preserver-spaces="true">Montez, J. K., Hayward, M. D., &amp; Zajacova, A. (2021). Trends in US population health: the central role of policies, politics, and profits.&nbsp;</span><em><span data-preserver-spaces="true">Journal of Health and Social Behavior, 62(3),</span></em><span data-preserver-spaces="true">&nbsp;286&ndash;301.</span></p>
                        <p><span data-preserver-spaces="true">https://doi.org/10.1177/00221465211015411</span></p>
                        <p><span data-preserver-spaces="true">Reist, C., Petiwala, I., Latimer, J., Raffaelli, S. B., Chiang, M., Eisenberg, D., &amp; Campbell, S. (2022). Collaborative mental health care: A narrative review.&nbsp;</span><em><span data-preserver-spaces="true">Medicine, 101(52).</span></em></p>
                        <p><span data-preserver-spaces="true">https://doi.org/10.1097/md.0000000000032554</span></p>
                        <p><span data-preserver-spaces="true">Swope, C. B., Hern&aacute;ndez, D., &amp; Cushing, L. J. (2022). The relationship of historical redlining with present-day neighborhood environmental and health outcomes: A scoping review and conceptual model.&nbsp;</span><em><span data-preserver-spaces="true">Journal of Urban Health, 99(6).</span></em></p>
                        <p><span data-preserver-spaces="true">https://doi.org/10.1007/s11524-022-00665-z</span></p>
                        <p><span data-preserver-spaces="true">Warren, M. D., &amp; Kavanagh, L. D. (2023). Over a century of leadership for maternal and child health in the United States: An updated maternal and child health bureau history.&nbsp;</span><em><span data-preserver-spaces="true">Maternal and Child Health Journal.</span></em></p>
                        <p><span data-preserver-spaces="true">https://doi.org/10.1007/s10995-023-03629-0</span></p>
                        <h2><strong><span data-preserver-spaces="true">People Search For More</span></strong></h2>
                        <p><strong><span data-preserver-spaces="true">What topics are covered in the "HS230 M5 Evolution of the US Healthcare Delivery System" module?</span></strong></p>
                        <p><span data-preserver-spaces="true">Historical changes and key legislative milestones in US healthcare are discussed.</span></p>
                        <p><strong><span data-preserver-spaces="true">Why is the module "HS230 M5 Evolution of the US Healthcare Delivery System" important?</span></strong></p>
                        <p><span data-preserver-spaces="true">Major reforms leading to the impact on the current healthcare structure are outlined.</span></p>
                        <p><strong><span data-preserver-spaces="true">How has the policy been directed by "HS230 M5 Evolution of the US Healthcare Delivery System"?</span></strong></p>
                        <p><span data-preserver-spaces="true">It shows how political changes have led to a shift in healthcare delivery over time.</span></p>
                        <p><strong><span data-preserver-spaces="true">What are the significant milestones in the "HS230 M5 Evolution of the US Healthcare Delivery System"?</span></strong></p>
                        <p><span data-preserver-spaces="true">It includes the Social Security Act and the Affordable Care Act, among other reforms.</span></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Hs230m5assignment