import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const N547week4assessment = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>N547 Week 4 Assessment | Directional Strategies Made Easy</title>
                <meta name='description'
                    content='Discover key takeaways in N547 Week 4 Assessment Directional Strategies Alternatives to improve decision-making. Click to Learn this guide!' />
                <meta name='keywords' content='N547 Week 4 Assessment Directional Strategies Alternatives' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>N547 Week 4 Assessment < br /><span>Directional Strategies Alternatives</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/N547week4assessment.webp" alt="N547 Week 4 Assessment Directional Strategies Alternatives" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>N547 Week 4 Assessment Directional Strategies Alternatives.</em></strong></li>
                        </ul>
                        <h2><strong>Directional Strategies and Developing Strategic Alternatives</strong></h2>
                        <p>The various aspects of strategic administration and decision-production in healthcare organizations are discussed in this assessment. Starting with investigating how strategy formulation relates to situational analysis, this assessment investigates the connections and implications of various strategic approaches. We looked at the mission, vision, and value statements of diverse healthcare organizations in America, including for-profit, not-for-profit, public, and private entities.</p>
                        <p>Finally, we compare the similarities and differences. These organizations prepared a Strategic Reasoning Guide for the American Hospital Association, reflecting how the various strategic options can be applied. The discussion will give an overview of the strategic issues in the healthcare industry.</p>
                        <h2><strong>Strategy Formulation and Situational Analysis</strong></h2>
                        <p>Strategy formulation is inextricably related to situational analysis in several important respects. Situational analysis, or analyzing an association's inward and outer environments, is the first step in the strategic planning process. This process is necessary because it enlightens decision-makers regarding the current status of the association in question, its strengths and weaknesses, and the external factors that will impact its future.</p>
                        <p>The essential association between situational analysis and strategy formulation lies in the social occasion of basic information critical for making strategic choices. This includes understanding the association's strengths, weaknesses, opportunities, and threats (SWOT analysis), a central aspect of situational analysis (Tawse and Tabesh, 2020). This SWOT analysis helps recognize the areas where the association can use its strengths, relieve weaknesses, seize opportunities, and safeguard against threats.</p>
                        <ul>
                            <li>
                                <h3><strong>Insights for Strategy Formulation</strong></h3>
                            </li>
                        </ul>
                        <p>These insights from the situational analysis in the strategy formulation are applied to develop the best strategies that suit the association's objectives and are responsive to the outer climate. For instance, a clear view of the business sector, competitor trends, and customer preferences helps establish the strategic direction. It can lead to decisions on entry into new markets, new product development, or even the adoption of new technology.</p>
                        <p>Situational analysis provides a basis for assessing resource distribution and capabilities, which are critical elements in strategy formulation. An inside analysis of the association's resources, processes, and past performance can feature areas for development and potential for development (Tawse and Tabesh, 2020). This enables the formulation of realistic, attainable, and custom-fitted strategies for the association's unique setting.</p>
                        <p>Not to neglect, situational analysis is often performed along with projections concerning future trends and scenarios of another critical dimension: long-term strategic planning. This way, such strategic alternatives are appealing and easy to follow if the business climate is modified further.</p>
                        <h3><strong>Retrenchment and Penetration Strategies</strong></h3>
                        <p>In N 547 Week 4 Assessment, Directional Strategies Alternation, reduction, and penetration, though sounding like opposites, an overlap in an all-encompassing strategic configuration. A retrenchment strategy is, therefore, a scaling-down operation to stabilize or revitalize the organization, usually due to poor financial performance, market forces, or other negative factors. This may mean cutting costs, divestiture of non-core investments, or reorganization frameworks (Tien Phat Pham, 2022).</p>
                        <p>Then again, a market penetration strategy focuses on increasing a piece of the pie for an existing item in an existing business sector, often through competitive estimating, increased advancement, or item improvements.</p>
                        <h3><strong>Logical Combinations of Strategies</strong></h3>
                        <p>The linkage between the strategies can be traced through the strategic aim of streamlining resources towards more focused development. To this end, an organization could use a retrenchment strategy to streamline its operations resources in a far more focused manner. Streamlining would make the establishment, after consolidation, more focused but also more monetarily sound to build aggressive market penetration initiatives.</p>
                        <p>For example, the organization will divest some unprofitable segments by concentrating on core areas with a comparative advantage where they can develop their pie. The third logical pairing of strategies pertains to market development and diversification: market development embraces entering new markets with the existing range of products, but diversification is about penetrating new ones (Tien Phat Pham, 2022).</p>
                        <ul>
                            <li>
                                <h3><strong>Strategic Market Expansion Opportunities</strong></h3>
                            </li>
                        </ul>
                        <p>These can be combined when a company discovers new market opportunities for which its existing capabilities can be used to offer new products. This combines development and risk spreading since the company is not dependent on its existing markets or products. Similarly, product improvement and market penetration can also be strategically matched.</p>
                        <p>An organization may invest in developing new features or versions of its existing product improvement- and simultaneously employ penetration strategies to boost sales of these revamped products in its existing business sector. This way, an organization remains vibrant and aggressive in its existing business sector due to continuous improvement of its product offerings and its pie increase.</p>
                        <h2><strong>Combination of Strategies Related to Vision</strong></h2>
                        <p>A strategic bundle tends to be uncontrollably tied to an organization's vision. The vision statement reveals the long-term visions of an organization by showing what it wants to get into from now on. Therefore, strategic bundles are made to propel this vision that directs organizations in specific ways.</p>
                        <p>In N547 Week 4 Assessment Directional Strategies Alternatives, if an organization's vision is to be the innovator in sustainable energy solutions, it could join strategies like innovation (to foster new sustainable products), market development (to explore new geographic areas with demand for sustainable energy), and corporate social responsibility (to enhance brand reputation and appeal to environmentally conscious consumers).</p>
                        <h2><strong>Mission, Vision, and Value Statements</strong></h2>
                        <p>These mission, vision, and value statements reflect an association's character and mirror the organization's goal, ethos, and functional philosophy. In healthcare, these guidelines guide organizations in expressing care and service. Some examples of the different healthcare organizations present in the US include:</p>
                        <ul>
                            <li>
                                <h3><strong>For-Profit Healthcare Organizations</strong></h3>
                            </li>
                        </ul>
                        <h4><strong>Baylor Scott and White Health</strong></h4>
                        <p><em><strong>Mission:</strong> As an association founded as a Christian healing ministry, this promotes the well-being of people, families, and communities.</em></p>
                        <p><em><strong>Vision:</strong> "Empowering you to live well."</em></p>
                        <p><em><strong>Values:</strong> Entail serving with integrity, never settling, teamwork, and making a difference(White Health, 2023).</em></p>
                        <ul>
                            <li>
                                <h3><strong>Not-For-Profit Healthcare Organizations</strong></h3>
                            </li>
                        </ul>
                        <p><strong>Cedars-Sinai</strong></p>
                        <p><em><strong>Mission:</strong> To improve the health status of the communities served, grounded in the Jewish heritage obliging commitment to healing.</em></p>
                        <p><em><strong>Vision:</strong> Treasured and respected internationally as transforming healthcare in Los Angeles and the world.</em></p>
                        <p><em><strong>Values:</strong> Incorporate greatness, uprightness, diversity, value, inclusion, respect, compassion, cooperation, advancement, accessibility, affordability, and stewardship (Cedars-Sinai, 2023).</em></p>
                        <ul>
                            <li>
                                <h3><strong>Public Healthcare Organizations</strong></h3>
                            </li>
                        </ul>
                        <p><strong>East Tennessee Children's Hospital</strong></p>
                        <p><em><strong>Mission:</strong> "To work on children's health through conveyance of care, instruction, and research" (Net, 2023).</em></p>
                        <p><em><strong>Vision:</strong> Their vision is to improve the children's health through a family-focused approach.</em></p>
                        <p><em><strong>Values:</strong> Incorporate Compassion, Respect, and Greatness.</em></p>
                        <ul>
                            <li>
                                <h3><strong>Private Healthcare Organizations</strong></h3>
                            </li>
                        </ul>
                        <h4><strong>St. David's Healthcare</strong></h4>
                        <p><em><strong>Mission:</strong> "To serve every patient consistently with a spirit of warmth, hospitality, and personal pride."</em></p>
                        <p><em><strong>Vision:</strong> The reference source needs to provide information on vision.</em></p>
                        <p><em><strong>Values:</strong> The source reference does not avail values, Lica, 2021.</em></p>
                        <ul>
                            <li>
                                <h3><strong>Massachusetts General Hospital (Department of Neurology)</strong></h3>
                            </li>
                        </ul>
                        <p><em><strong>Mission:</strong> To be the leading US academic neurological department, offering the highest quality clinical care while developing new treatments for neurological disorders.</em></p>
                        <p><em><strong>Vision:</strong> The reference source needs to provide information on vision.</em></p>
                        <p><em><strong>Values:</strong> Embed rapid translation of laboratory advances to patient consideration and seamless integration of research (Lica, 2021).</em></p>
                        <h2><strong>For-Profit vs. Not-For-Profit Healthcare Organizations</strong></h2>
                        <ul>
                            <li>
                                <h3><strong>Similarities</strong></h3>
                            </li>
                        </ul>
                        <p>Both for-profit (like Baylor Scott and White Health, Cardinal Health Inc.) and not-for-profit (like Cedars-Sinai) organizations focus on successful healthcare conveyance in their mission statements. This demonstrates a universal obligation to health and prosperity in the healthcare industry. They emphasize top-caliber, imaginative healthcare solutions. For instance, CVS Caremark Corp focuses on creative health and drug store services, while Cedars-Sinai emphasizes research and schooling in healthcare.</p>
                        <ul>
                            <li>
                                <h3><strong>Differences</strong></h3>
                            </li>
                        </ul>
                        <p>For-profit organizations, like Baylor Scott and White Health, are bound to emphasize proficiency and efficiency in their values since this reflects their profit-arranged nature. On the contrary, not-for-profit organizations like Cedars-Sinai often incorporate more diversity, value, and inclusion values, mirroring their local area situated focus.</p>
                        <p>Not-for-profit organizations may also possess deeper, more comprehensive ranges of moral and community-based values. The mission of the Cedars-Sinai is based upon Judaic practice. It embodies many values, such as respect, compassion, and worth, showing a more profound commitment to community-level governance support.</p>
                        <h2><strong>Public vs. Private Healthcare Organizations</strong></h2>
                        <ul>
                            <li>
                                <h3><strong>Similarities</strong></h3>
                            </li>
                        </ul>
                        <p>Both public (e.g., East Tennessee Children's Hospital) and private (e.g., St. David's Healthcare) healthcare organizations strongly focus on understanding consideration in their mission statements. This indicates a universal healthcare industry pattern towards patient-centeredness. There's a shared emphasis on conveying excellent healthcare services. For instance, East Tennessee Children's Hospital highlights comprehensive pediatric healthcare, reflecting the private sector's focus on uncommon consideration of quality.</p>
                        <ul>
                            <li>
                                <h3><strong>Differences</strong></h3>
                            </li>
                        </ul>
                        <p>The mission and values in public organizations more often refer to accessibility and affordability in healthcare. It can be found in the example of East Tennessee Children's Hospital's mission: to promise care regardless of the patient's experience. In private organizations, some features of healthcare conveyance may become a priority, like in St. David's Healthcare - remarkable consideration with warmth and personal pride.</p>
                        <ul>
                            <li><strong>Public vs. Private Healthcare Focus</strong></li>
                        </ul>
                        <p>Public healthcare entities may focus more on general public health goals and values, while private organizations could focus more on specialized care and development. For instance, the Department of Neurology at Massachusetts General Hospital, being a private element, focuses on research and state-of-the-art treatments, hence more specialized and development-oriented.</p>
                        <h2><strong>Hierarchy of Strategic Decisions and Alternatives</strong></h2>
                        <p>In N 547 Week 4 Assessment Directional Strategies Alternatives, I will discuss a few strategies exhibiting the "Hierarchy of Strategic Decisions and Alternatives" for the American Hospital Association (AHA) by providing alternatives in each category. AHA is a not-for-profit association of healthcare providers dedicated to advancing the health of individuals and communities.</p>
                        <h3><strong>Corporate Strategy</strong></h3>
                        <ul>
                            <li><strong>Development:</strong> AHA can research collaboration with companies that focus on innovation to enhance health IT products.</li>
                            <li><strong>Stability:</strong> Focus on cementing established support and policy initiatives, but not expanding.</li>
                            <li><strong>Retrenchment:</strong> Scale back less viable support programs to apportion resources all the more productively.</li>
                        </ul>
                        <h3><strong>Business Strategy</strong></h3>
                        <ul>
                            <li><strong>Cost Leadership:</strong> Foster cost-viable preparation programs for healthcare professionals to upgrade affordability.</li>
                            <li><strong>Separation:</strong> Offer novel, top-notch strategy guidance and healthcare thought leadership.</li>
                            <li><strong>Focus:</strong> Focus on specific healthcare segments like rustic healthcare or advanced health.</li>
                        </ul>
                        <h3><strong>Functional Strategy</strong></h3>
                        <ul>
                            <li><strong>Operations:</strong> Work on functional proficiency using cutting-edge information analytics for strategy research.</li>
                            <li><strong>Advertising:</strong> Focus on cementing established support and policy initiatives, but not expanding.</li>
                            <li><strong>HR:</strong> Well-resourced representative development programs help attract and retain high-achieving talent to perform healthcare advocacy functions.</li>
                        </ul>
                        <h3><strong>Competitive Strategy</strong></h3>
                        <ul>
                            <li><strong>Offensive: Aggressively</strong> push relevant health care policies forward, supporting AHA's vision.</li>
                            <li><strong>Defensive:</strong> Negative proposed policies or emerging trends that have, or will, impact health care negatively.</li>
                            <li><strong>Versatile:</strong> Change promotion tactics accordingly as the healthcare environment and policy changes change over time.</li>
                        </ul>
                        <h3><strong>Tactical Decisions</strong></h3>
                        <ul>
                            <li><strong>Short-term:</strong> Send a team to focus on immediate health needs, such as pandemic response.</li>
                            <li><strong>Mid-term:</strong> Nurture a holistic structure supporting health systems pursuing computerized health technologies.</li>
                            <li><strong>Long-term:</strong> Define the future of healthcare for the American people, including those long-term systemic changes the country needs.</li>
                        </ul>
                        <p>The AHA can find its way through these strategic options by balancing short-term needs with long-term objectives. Each strategy type offers multiple routes, reflecting the organization's flexibility and responsiveness to the dynamic healthcare environment.</p>
                        <h2><strong>Conclusion</strong></h2>
                        <p>This assessment has shed light on the multi-faceted thought of strategic healthcare decision-making- health, analyzing how the situational analysis informs the strategy formulation to comparing different organizational missions, visions, and values in various healthcare sectors-we gathered knowledge about the challenges faced by healthcare executives.</p>
                        <p>The exercise, including the American Hospital Association (AHA), has further demonstrated the practical application of different strategic options; what is needed are versatile approaches tailored to yield solutions to unique challenges pertinent to the healthcare industry. Such an in-depth study amply underlines the essential role strategic thinking plays for healthcare organizations on their paths to success and sustainability.</p>
                        <h2><strong>References</strong></h2>
                        <p>Cedars-Sinai. (2023). <em>Our Mission, Vision, and Values</em>. Cedars-Sinai.</p>
                        <p>https://www.cedars-sinai.org/about/mission.html</p>
                        <p>Lica, S. (2021, October 14). <em>Healthcare Mission, Vision, &amp; Values Statements: A Guide</em>.</p>
                        <p>https://www.berxi.com/resources/articles/healthcare-mission-vision-and-values-statements/</p>
                        <p>Net, S. (2023, March 21). <em>Best Mission Statements Examples [for Healthcare Companies]</em>. Status.net.</p>
                        <p>https://status.net/articles/vision-mission-statements-examples-healthcare-companies/</p>
                        <p>Tawse, A., &amp; Tabesh, P. (2020). Strategy implementation: A review and an introductory framework. <em>European Management Journal</em>, <em>39</em>(1).</p>
                        <p>https://doi.org/10.1016/j.emj.2020.09.005</p>
                        <p>Tien Phat Pham. (2022). <em>Current Issue - Volume 7, Number 3, September-December 2023 | JOMEINO</em>. Jomeino.com.</p>
                        <p>https://jomeino.com/sites/default/files/paper_attachment/Fintech%2C%20strategic%20adaptation%20in%20shock%20of%20COVID-19_0.pdf</p>
                        <p>White Health, B. S. (2023, May). <em>About Baylor Scott &amp; White Health | Baylor Scott &amp; White Health</em>.</p>
                        <p>Www.bswhealth.com. https://www.bswhealth.com/about</p>
                        <h2><strong>People Search For More</strong></h2>
                        <p><strong>What are the significant objectives of N 547 Week 4 Assessment Directional Strategies Alternatives?</strong><strong><br /></strong>The primary objective is to explore various directional strategies and alternatives for healthcare organizations such as AHA.</p>
                        <p><strong>How does the N 547 Week 4 Assessment Directional Strategies Alternatives help the healthcare institution?</strong><strong><br /></strong>It helps healthcare institutions to identify strategic alternatives for growth and efficiency and to improve community health.</p>
                        <p><strong>What is the focus of N 547 Week 4 Assessment Directional Strategies Alternatives?</strong><strong><br /></strong>It focuses on assessing different strategic options and decisions health organizations can make to enhance their operation.</p>
                        <p><strong>What kind of strategies does N 547 Week 4 Assessment Directional Strategies Alternatives discuss?</strong><strong><br /></strong>The discussed strategies are reduction, market development, and innovation in healthcare management.</p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default N547week4assessment