import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Mbafpx5910assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>MBA FPX 5910 Assessment 2 | Capstone Proposal</title>
                <meta name='description'
                    content="Discover a comprehensive review of ExxonMobil's climate strategies in MBA FPX 5910 Assessment 2. Learn how sustainability aligns with corporate growth." />
                <meta name='keywords' content='MBA FPX 5910 Assessment 2 Capstone Proposal' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>MBA FPX 5910 Assessment 2 < br /><span>Capstone Proposal</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Mbafpx5910assessment2.webp" alt="MBA FPX 5910 Assessment 2 Capstone Proposal" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>MBA FPX 5910 Assessment 2 Capstone Proposal</em></strong></li>
                        </ul>
                        <h2><strong>Introduction</strong></h2>
                        <p>ExxonMobil is one of the largest oil and gas corporations globally, with operations in over 50 countries and a workforce exceeding 74,900. The company&rsquo;s scope spans the production of crude oil, natural gas, petrochemicals, and petroleum-based products. In 2020, ExxonMobil reported revenues of $256 billion, placing it among the top-ranking organizations worldwide (Forbes, 2020).</p>
                        <p>Despite its scale and success, ExxonMobil has faced significant criticism from environmentalists, scientists, and the public. These criticisms include oil spill incidents, resistance to renewable energy advancements, denial of climate change, and insufficient progress in adopting sustainable practices. To address these challenges, this consultancy report outlines an actionable plan to help ExxonMobil navigate environmental concerns and position itself as a forward-thinking organization.</p>
                        <h2><strong>Summary</strong></h2>
                        <p>This consultancy report evaluates ExxonMobil's historical environmental challenges and actions related to climate change, oil spill incidents, policy influence, and investment priorities. It also analyzes the economic costs of environmental damage and public backlash, providing actionable recommendations for sustainable practices. The aim is to align ExxonMobil&rsquo;s business strategies with environmental responsibility while maintaining profitability and brand integrity.</p>
                        <h2><strong>Key Areas of Analysis</strong></h2>
                        <h3><strong>Environmental Incidents and Costs</strong></h3>
                        <p>Over the past two decades, ExxonMobil&rsquo;s operations have resulted in numerous environmental accidents, including oil spills and related penalties. These incidents damage ecosystems and impose significant financial and reputational costs on the company.</p>
                        <h3><strong>Climate Change Denial and Public Perception</strong></h3>
                        <p>Research, including studies by Supran and Oreskes (2017), has highlighted ExxonMobil&rsquo;s historical denial of climate change and its funding of organizations that downplay the urgency of environmental action. This resistance has led to public distrust and criticism, intensifying the demand for transparency and accountability.</p>
                        <h3><strong>Policy Influence and Lobbying Efforts</strong></h3>
                        <p>ExxonMobil&rsquo;s lobbying efforts have been accused of interfering with environmental regulations and influencing U.S. foreign policy (Ambrose, 2019). These activities have contributed to the perception that the company prioritizes profit over environmental and social responsibility.</p>
                        <h3><strong>Investment in Renewable Energy</strong></h3>
                        <p>Although ExxonMobil has made some progress in exploring renewable energy sources, the pace still needs to improve compared to that of industry leaders. A greater focus on developing and commercializing alternative energy solutions could improve the company&rsquo;s long-term viability and public image.</p>
                        <h2><strong>Proposed Strategy and Scope</strong></h2>
                        <h3><strong>Strategic Areas of Focus</strong></h3>
                        <ol>
                            <li><strong>Analyze Historical Environmental Incidents:</strong></li>
                            <li>Investigate ExxonMobil&rsquo;s record of oil spills, environmental fines, and related costs to the company and the public.</li>
                            <li><strong>Assess Climate Change Research and Funding:</strong></li>
                            <li>Evaluate the company&rsquo;s internal research on climate change and its support for organizations that deny environmental realities (Grandoni, 2020).</li>
                            <li><strong>Examine Policy and Regulatory Activities:</strong></li>
                            <li>Explore allegations of ExxonMobil&rsquo;s role in shaping U.S. policy and efforts to block environmental regulations (Kelly, 2019).</li>
                            <li><strong>Develop Business-Friendly Environmental Policies:</strong></li>
                            <li>Propose actionable steps for ExxonMobil to adopt sustainable business practices without compromising profitability.</li>
                            <li><strong>Accelerate Renewable Energy Development:</strong></li>
                            <li>Recommend strategies for increased investment in renewable energy research, development, and commercialization.</li>
                            <li><strong>Address Public Perception:</strong></li>
                            <li>Provide tools for ExxonMobil to rebuild trust and effectively respond to public criticism while maintaining brand strength.</li>
                        </ol>
                        <h2><strong>Action Plan</strong></h2>
                        <ol>
                            <li><strong>Define Core Areas of Concern:</strong></li>
                            <li>Focus on ExxonMobil&rsquo;s environmental protection record and climate change denial as central issues. Addressing these areas aligns the organization&rsquo;s goals with current consumer and regulatory demands.</li>
                            <li><strong>Source Reliable Data:</strong></li>
                            <li>Collect information from reputable sources, including ExxonMobil&rsquo;s official reports, news outlets like <em>The New York Times</em> and <em>Washington Post</em>, peer-reviewed studies, and academic databases like Google Scholar. Relevant search terms include "ExxonMobil environmental impact," "climate change denial," and "future of renewable energy."</li>
                            <li><strong>Analyze and Synthesize Data:</strong></li>
                            <li>Organize findings thematically, highlighting key issues and actionable insights. Develop evidence-based recommendations grounded in academic and industry best practices.</li>
                            <li><strong>Present Recommendations:</strong></li>
                            <li>Offer detailed solutions, including fostering an eco-friendly business culture, accelerating renewable energy projects, and adopting transparent communication strategies.</li>
                            <li><strong>Structure the Report:</strong></li>
                            <li>Format the report with the following sections:</li>
                            <ul>
                                <li><strong>Cover Page</strong></li>
                                <li><strong>Executive Summary</strong></li>
                                <li><strong>Introduction</strong></li>
                                <li><strong>Core Issues and Findings</strong></li>
                                <li><strong>Recommendations</strong></li>
                                <li><strong>Conclusion</strong></li>
                                <li><strong>References</strong></li>
                            </ul>
                        </ol>
                        <h2><strong>Application of BetterUp Assessment Results</strong></h2>
                        <p>The BetterUp Assessment highlights my strengths in flow, motivation, and coaching, which are pivotal for this project:</p>
                        <ol>
                            <li><strong>Flow:</strong></li>
                            <li>This strength enables me to immerse myself in research and analysis, ensuring meticulous attention to the accuracy and depth of the findings.</li>
                            <li><strong>Motivation:</strong></li>
                            <li>My passion for environmental sustainability drives me to create compelling, evidence-based recommendations that inspire ExxonMobil to adopt transformative changes.</li>
                            <li><strong>Coaching:</strong></li>
                            <li>As a business coach, I can effectively communicate insights and strategies that resonate with ExxonMobil&rsquo;s leadership, fostering buy-in for sustainable initiatives.</li>
                        </ol>
                        <h2><strong>Conclusion</strong></h2>
                        <p>This <a href="https://www.coursehero.com/sitemap/schools/62417-Capella-University/courses/20290610-MBAFPX5910/"><em>MBA FPX 5910 Assessment 2</em></a> report addresses ExxonMobil&rsquo;s environmental challenges, offering a pathway to aligning its operations with sustainability goals. By tackling climate change denial, prioritizing renewable energy, and improving public perception, ExxonMobil can secure its position as a responsible global leader.</p>
                        <p>The actionable recommendations balance environmental accountability with business profitability, ensuring long-term success. With permission, I am eager to proceed with the next phase of this capstone project.</p>
                        <h2><strong>References</strong></h2>
                        <p><strong>Ambrose, J.</strong> (2019). Major global firms are accused of concealing their environmental impact. <em>The Guardian</em>. Retrieved August 15, 2020, from<a href="https://www.theguardian.com/environment/2019/jun/16/major-global-firms-accused-of-concealing-their-environmental-impact"> https://www.theguardian.com/environment/2019/jun/16/major-global-firms-accused-of-concealing-their-environmental-impact</a></p>
                        <p><strong>Forbes.</strong> (2020). ExxonMobil (XOM). <em>Forbes</em>. Retrieved August 15, 2020, from<a href="https://www.forbes.com/companies/exxon-mobil/#5c597c29601f"> https://www.forbes.com/companies/exxon-mobil/#5c597c29601f</a></p>
                        <p><strong>Grandoni, D.</strong> (2020). The Energy 202: ExxonMobil declines to set long-term climate goals, bucking the oil industry trend. <em>The Washington Post</em>. Retrieved August 15, 2020, from<a href="https://www.washingtonpost.com/news/powerpost/paloma/the-energy202/2020/05/28/the-energy-202-exxonmobil-declines-to-set-long-term-climate-goal-bucking-oil-industry-trend/5ece8a9788e0fa6727006975/">https://www.washingtonpost.com/news/powerpost/paloma/the-energy202/2020/05/28/the-energy-202-exxonmobil-declines-to-set-long-term-climate-goal-bucking-oil-industry-trend/5ece8a9788e0fa6727006975/</a></p>
                        <p><strong>Kelly, S.</strong> (2019). Revealed: Mobil sought to fight environmental regulation, documents show. <em>The Guardian</em>. Retrieved August 15, 2020, from<a href="https://www.theguardian.com/business/2019/jun/12/mobil-tax-exempt-donations-promote-interests-environmental-regulation-documents"> https://www.theguardian.com/business/2019/jun/12/mobil-tax-exempt-donations-promote-interests-environmental-regulation-documents</a></p>
                        <p><strong>Supran, G., &amp; Oreskes, N.</strong> (2017). Assessing ExxonMobil's climate change communications (1977&ndash;2014). <em>Environmental Research Letters, 12</em>(8), 084019.<a href="https://doi.org/10.1088/1748-9326/aa815f"> https://doi.org/10.1088/1748-9326/aa815f</a></p>                   </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Mbafpx5910assessment2
